import { SignalFilled } from "@ant-design/icons";
import { NavLink, Navigate, Outlet, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { getNavLinkClassName } from "../../utils/activeNavLinkClassName";
import { Page } from "../App/Page";
import { AppRoutes } from "../App/routeConstants";
import { CatalogProvider } from "../Catalog/CatalogProvider";
import { Layout } from "./Layout";
import { FindingsProvider } from "./contexts/FindingsContext";
import { ScopeProvider } from "./contexts/ScopeContext";
import { SelectedAssessmentProvider } from "./contexts/SelectedAssessmentContext";
import { Explore } from "./pages/Explore";
import { FindingDetail } from "./pages/FindingDetail";
import { Findings } from "./pages/Findings";
import { Management } from "./pages/Management";
import { MonitorResults } from "./pages/MonitorResults";
import { Overview } from "./pages/Overview";
import { QueryResult } from "./pages/QueryResult";
import { Settings } from "./pages/Settings";

export const AssessmentRoutes = (
  <>
    <Route
      path={AppRoutes.IamAssessment}
      element={
        <Page title="IAM Assessment">
          <CatalogProvider>
            <Outlet />
          </CatalogProvider>
        </Page>
      }
    >
      <Route index element={<Overview />} />
      <Route
        path=":assessmentId"
        element={
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <SelectedAssessmentProvider>
              <Outlet />
            </SelectedAssessmentProvider>
          </QueryParamProvider>
        }
      >
        <Route index element={<Navigate to="findings" />} />
        <Route
          path="settings"
          element={
            <FindingsProvider>
              <Layout>
                <Settings />
              </Layout>
            </FindingsProvider>
          }
        />
        <Route
          path="management"
          element={
            <ScopeProvider>
              <FindingsProvider>
                <Layout>
                  <Management />
                </Layout>
              </FindingsProvider>
            </ScopeProvider>
          }
        />
        <Route
          element={
            <ScopeProvider>
              <FindingsProvider>
                <Layout>
                  <Outlet />
                </Layout>
              </FindingsProvider>
            </ScopeProvider>
          }
        >
          <Route path="explore">
            <Route index element={<Explore />} />
            <Route path=":nodeType/:nodeKey" element={<QueryResult />} />
          </Route>
          <Route path="findings" element={<Findings />} />
          <Route path="monitors/:monitorId">
            <Route index element={<MonitorResults />} />
            <Route path="findings">
              <Route path=":findingId" element={<FindingDetail />} />
            </Route>
          </Route>
        </Route>
        {/* Prevent re-direction all the way to requests;
         * use 'replace' so that back button works
         */}
        <Route
          path="*"
          element={
            <Navigate
              to={{ pathname: "findings", search: "?scope=all" }}
              replace
            />
          }
        />
      </Route>
    </Route>
  </>
);

export const AssessmentMenu = {
  key: AppRoutes.IamAssessment,
  icon: <SignalFilled />,
  label: (
    <NavLink to={AppRoutes.IamAssessment} className={getNavLinkClassName}>
      IAM Assessments
    </NavLink>
  ),
};
