import {
  Col,
  Collapse,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { BaselineDiv } from "components/divs";
import { User } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { jwtDecode } from "jwt-decode";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { handleErrorWithToastMessage } from "utils/error";

import {
  DB,
  useFirestoreCollection,
  useFirestoreDoc,
} from "../../providers/FirestoreProvider";
import { Heading } from "../Heading";
import { useAuthFetch, useUser } from "../Login/hook";
import { Tenant, UserRole } from "../Login/util";
import { TagInput } from "../TagInput";
import { ApiKeysPanel } from "./ApiKeysPanel";
import { ApprovalRequirements } from "./ApprovalRequirements";

const { Text, Title } = Typography;

const StyledTagInput = styled(TagInput)`
  margin-top: 0.5em;
`;

const REMOVE_OWN_ACCESS_ERROR =
  "You can't remove yourself as an owner. Instead, add another member of your organization, then have them remove you.";

// @IMPORTANT!!!
// If you need to make changes to this Settings UI, you should also
// make the same changes to the SettingsSandbox page to keep them
// relatively in sync.

// @TODO: Separate the model/controller side of this component
// from the view side. That way we can have a layer that switches
// the data between sandbox and non-sandbox and keep the same
// view code.
const MembersEdit: React.FC<{ role: UserRole }> = ({ role }) => {
  const tenantId = useContext(Tenant);
  const { user } = useUser();
  const authFetch = useAuthFetch(handleErrorWithToastMessage);
  const derivedRole = useMemo(
    () => role.replace("approver", "manager"),
    [role]
  );
  const path = useMemo(
    () => `o/${tenantId}/roles/${derivedRole}/bindings`,
    [derivedRole, tenantId]
  );
  const membersDocs = useFirestoreCollection<{ email: string }>(path, {
    live: true,
  });
  const members = useMemo(
    () => membersDocs?.map((m) => m.data.email ?? m.id) ?? [],
    [membersDocs]
  );

  const updateMembership = useCallback(
    (method: "DELETE" | "PUT", email: string) =>
      authFetch(
        `settings/roles/${derivedRole}/bindings/users/${encodeURIComponent(
          email
        )}`,
        { method }
      ),
    [authFetch, derivedRole]
  );

  const onChange = useMemo(
    () => async (newMembers: string[]) => {
      const added = newMembers.filter((m) => members.indexOf(m) === -1);
      const removed = members.filter((m) => newMembers.indexOf(m) === -1);
      if (added || removed) {
        for (const email of added) {
          const response = await updateMembership("PUT", email);
          if (response) message.success(`Added ${email}`);
        }
        for (const email of removed) {
          const response = await updateMembership("DELETE", email);
          if (response) message.success(`Removed ${email}`);
        }
      }
    },
    [members, updateMembership]
  );

  return membersDocs === undefined ? (
    <Spin />
  ) : (
    <BaselineDiv>
      <div>Users:</div>
      <SettingsTagInput
        user={user}
        role={role}
        tags={members}
        onChange={onChange}
      />
    </BaselineDiv>
  );
};

const SettingsTagInput: React.FC<{
  tags: string[];
  role: UserRole;
  user?: User;
  onChange: (tags: string[]) => void;
}> = ({ tags, role, user, onChange }) => {
  const tagToElement = useCallback(
    (tag: string, onClose: () => void) => {
      const canClose = role !== "owner" || tag !== user?.email;
      const key = tag;
      const tagElem = (
        <Tag key={key} closable={canClose} onClose={onClose}>
          {tag}
        </Tag>
      );
      return canClose ? (
        tagElem
      ) : (
        <Tooltip key={key} title={REMOVE_OWN_ACCESS_ERROR}>
          {tagElem}
        </Tooltip>
      );
    },
    [role, user]
  );

  return (
    <StyledTagInput
      placeholder="Enter an email address"
      tags={tags}
      // Superset pattern from https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
      tagPattern={/[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-@]/}
      tagToElement={tagToElement}
      onUpdate={onChange}
      key={role}
    />
  );
};

/** Edits groups that can access a role */
const GroupsEdit: React.FC<{ role: UserRole }> = ({ role }) => {
  const tenantId = useContext(Tenant);
  const { user } = useUser();
  const authFetch = useAuthFetch(handleErrorWithToastMessage);
  const derivedRole = useMemo(
    () => role.replace("approver", "manager"),
    [role]
  );
  const path = useMemo(
    () => `o/${tenantId}/roles/${derivedRole}`,
    [derivedRole, tenantId]
  );
  const roleData = useFirestoreDoc<{ allowed?: { groups: string[] } }>(path, {
    live: true,
  });
  const groups = useMemo(
    () => roleData.doc?.data.allowed?.groups ?? [],
    [roleData]
  );
  const userGroups = useMemo(() => {
    if (!user) return undefined;
    // The user groups are not in clear-text on the user record; but, rather, encoded in the access token :(
    // Moreover the access token is not on the User interface
    const userJwtData = jwtDecode<{
      firebase: { sign_in_attributes?: { groups?: string[] } };
    }>((user as any).accessToken);
    return userJwtData.firebase.sign_in_attributes?.groups;
  }, [user]);

  const updateGroupMembership = useCallback(
    (method: "DELETE" | "PUT", group: string) =>
      authFetch(
        `settings/roles/${derivedRole}/bindings/groups/${encodeURIComponent(
          group
        )}`,
        { method }
      ),
    [authFetch, derivedRole]
  );

  const saveGroups = useCallback(
    async (newGroups: string[]) => {
      if (!user || !userGroups) return;
      if (derivedRole === "owner") {
        // Prevent owners from removing the last binding that grants them ownership
        const userIsInGroups = newGroups.find((g) => userGroups?.includes(g));
        if (!userIsInGroups) {
          const userBindingExists = (
            await getDoc(doc(DB, `${path}/bindings/${user.email}`))
          ).exists();
          if (!userBindingExists) {
            // TODO: Prevent closing of last group tag
            message.error(REMOVE_OWN_ACCESS_ERROR);
            return;
          }
        }
      }

      const addedGroups = newGroups.filter((g) => !groups.includes(g));
      const removedGroups = groups.filter((g) => !newGroups.includes(g));

      for (const addedGroup of addedGroups) {
        const response = await updateGroupMembership("PUT", addedGroup);
        if (response) message.success(`Groups ${newGroups} added`);
      }
      for (const removedGroup of removedGroups) {
        const response = await updateGroupMembership("DELETE", removedGroup);
        if (response) message.success(`Groups ${newGroups} removed`);
      }
    },
    [derivedRole, groups, path, updateGroupMembership, user, userGroups]
  );

  // If user does not have a groups claim, this provider is not compatible with group access
  return !userGroups ? null : roleData === undefined ? (
    <Spin />
  ) : (
    <BaselineDiv>
      <div>Groups:</div>
      <StyledTagInput
        placeholder="Enter a group name"
        tags={groups}
        tagPattern={/[a-zA-Z0-9 ]/}
        onUpdate={saveGroups}
        key={role}
      />
    </BaselineDiv>
  );
};

export const Settings: React.FC<object> = () => {
  const flags = useFlags();

  return (
    <>
      <Heading title="Settings" />
      <Collapse defaultActiveKey={["access_control", "workflows", "apikeys"]}>
        <Collapse.Panel header="Access control" key="access_control">
          <Row gutter={[0, 32]}>
            <Col span={24}>
              <Title level={4}>Owners</Title>
              Owners can add integrations and alter settings.
              <MembersEdit role="owner" />
              <GroupsEdit role="owner" />
            </Col>
            <Col span={24}>
              <Title level={4}>Approvers</Title>
              Approvers can approve and revoke access requests.
              <MembersEdit role="approver" />
              <div style={{ marginTop: "8px" }}>
                <Text type="secondary">
                  Use <Link to="../routing">Routing</Link> for granular approval
                  permissions
                </Text>
              </div>
            </Col>
            <Col span={24}>
              <Title level={4}>Assessment Users</Title>
              Assessment Users can run, manage, and view IAM assessments.
              <MembersEdit role="iamOwner" />
              <GroupsEdit role="iamOwner" />
            </Col>
            <Col span={24}>
              <Title level={4}>Assessment Viewers</Title>
              Assessment Viewers can view IAM assessments.
              <MembersEdit role="iamViewer" />
              <GroupsEdit role="iamViewer" />
            </Col>
            {flags.snowflakeRestStateManagement && (
              <Col span={24}>
                <Title level={4}>Rest State Manager</Title>
                Rest State Managers can configure rest state configuration and
                calculate drifts in snowflake configuration.
                <MembersEdit role="restStateManager" />
              </Col>
            )}
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Workflows" key="workflows">
          <ApprovalRequirements />
        </Collapse.Panel>
        <Collapse.Panel header="API Keys" key="apikeys">
          <ApiKeysPanel />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};
