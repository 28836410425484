import { limit, orderBy, where } from "firebase/firestore";
import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router";
import { toAssessmentPath } from "shared/assessment/helper";
import { isa } from "shared/types/is";

import {
  FirestoreDoc,
  useFirestoreCollection,
  useFirestoreDoc,
} from "../../../providers/FirestoreProvider";
import { IamAssessment } from "../../../shared/types/assessment";
import { Finding } from "../../../shared/types/assessment/finding";
import {
  AssessmentJob,
  TerminalAssessmentStatuses,
} from "../../../shared/types/assessment/job";
import { Tenant } from "../../Login";
import { useAuthFetch } from "../../Login/hook";

type SelectedAssessment = {
  assessment: {
    doc: FirestoreDoc<IamAssessment> | undefined;
    hasSchedule: boolean;
    isScheduled: boolean;
    loading: boolean;
  };
  finding: { doc: FirestoreDoc<Finding> | undefined; loading: boolean };
  current: {
    doc: FirestoreDoc<AssessmentJob> | undefined;
    loading: boolean;
    isCompleted: boolean;
    isInProgress: boolean;
  };
  last: {
    doc: FirestoreDoc<AssessmentJob> | undefined;
    loading: boolean;
  };
  runAssessmentNow: () => void;
};

export const SelectedAssessmentContext = createContext<SelectedAssessment>({
  assessment: {
    doc: undefined,
    loading: true,
    hasSchedule: false,
    isScheduled: false,
  },
  finding: { doc: undefined, loading: true },
  current: {
    doc: undefined,
    loading: true,
    isCompleted: false,
    isInProgress: false,
  },
  last: { doc: undefined, loading: true },
  runAssessmentNow: () => {},
});

export const SelectedAssessmentProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { assessmentId, findingId } = useParams();
  const tenantId = useContext(Tenant);
  const authFetch = useAuthFetch();

  const assessmentPath = toAssessmentPath(tenantId, assessmentId ?? "");
  const assessmentDoc = useFirestoreDoc<IamAssessment>(assessmentPath, {
    live: true,
  });

  const currentDocs = useFirestoreCollection<AssessmentJob>(
    `o/${tenantId}/job-state`,
    {
      live: true,
      queryConstraints: [
        where("assessmentId", "==", assessmentId),
        orderBy("lastUpdatedTimestamp", "desc"),
        limit(1),
      ],
    }
  );

  const lastCompletedDocs = useFirestoreCollection<AssessmentJob>(
    `o/${tenantId}/job-state`,
    {
      live: true,
      queryConstraints: [
        where("assessmentId", "==", assessmentId),
        where("status", "==", "COMPLETED"),
        orderBy("lastUpdatedTimestamp", "desc"),
        limit(1),
      ],
    }
  );

  // Is undefined unless user has navigated to a findings page
  const findingDoc = useFirestoreDoc<Finding>(
    findingId ? `${assessmentPath}/findings/${findingId}` : undefined,
    { live: true }
  );

  const value: SelectedAssessment = useMemo(
    () => ({
      assessment: {
        doc: assessmentDoc.doc,
        loading: assessmentDoc.loading,
        hasSchedule: assessmentDoc.doc?.data?.frequency !== undefined,
        isScheduled:
          !assessmentDoc.doc?.data?.frequency?.disabled &&
          // TODO: fix with migration
          assessmentDoc.doc?.data?.frequency?.anchorDate !== undefined,
      },
      finding: {
        doc: findingDoc.doc,
        loading: findingDoc.loading,
      },
      current: {
        doc: currentDocs?.[0],
        loading: currentDocs === undefined,
        isCompleted:
          currentDocs?.length === 0 ||
          Boolean(
            currentDocs?.[0] &&
              isa(TerminalAssessmentStatuses, currentDocs[0].data.status)
          ),
        isInProgress: Boolean(
          currentDocs?.[0] &&
            !isa(TerminalAssessmentStatuses, currentDocs[0].data.status)
        ),
      },
      last: {
        doc: lastCompletedDocs?.[0],
        loading: lastCompletedDocs === undefined,
      },
      runAssessmentNow: () => {
        authFetch(`assessment/${assessmentId}/run`, {
          method: "POST",
        });
      },
    }),
    [
      assessmentDoc.doc,
      assessmentDoc.loading,
      currentDocs,
      findingDoc.doc,
      findingDoc.loading,
      lastCompletedDocs,
      assessmentId,
      authFetch,
    ]
  );

  return (
    <SelectedAssessmentContext.Provider value={value}>
      {children}
    </SelectedAssessmentContext.Provider>
  );
};
