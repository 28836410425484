// TODO make the scopes optional based on the use case.
// There are two distinct use cases for these scopes:
// 1. okta.groups.manage and okta.users.read are used for Okta access requests
// 2. okta.apps.manage and okta.schemas.manage are used for requesting AWS roles with the  "Okta SAML" user provisioning method
export const OktaScopes = [
  "okta.apps.manage",
  "okta.groups.manage",
  "okta.schemas.manage",
  "okta.users.read",
] as const;
