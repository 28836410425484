import Link from "antd/lib/typography/Link";
import {
  iamWriteInstallCommands,
  iamWriteTerraform,
} from "shared/integrations/resources/aws/policy/iam-write";

import { AwsInstaller } from "../types";
import { loginInstaller } from "./login";

export const FEATURE_FLAG_LEAST_PRIVILEGE_AWS_P0_IAM_MANAGER =
  "leastPrivilegeAwsP0IamManagerRoleSshOnly";

const iamWriteInstructions: AwsInstaller["iam-write"]["instructions"] = (
  context,
  id
) => {
  const awsPolicyOptions = {
    sshOnly: context.featureFlags?.[
      FEATURE_FLAG_LEAST_PRIVILEGE_AWS_P0_IAM_MANAGER
    ] as boolean,
  };
  const serviceAccountId = context.config.base[id]?.serviceAccountId;

  return {
    help: (
      <>
        To install P0 on this account, provision P0&apos;s access using{" "}
        <Link
          href={`https://${id}.signin.aws.amazon.com/console`}
          target="_blank"
          rel="noopener"
        >
          AWS Cloud Shell
        </Link>{" "}
        or Terraform:
      </>
    ),
    commands: {
      shell: [
        {
          command: iamWriteInstallCommands(context, awsPolicyOptions, {
            gcloudServiceAccountId: serviceAccountId,
            awsAccountId: id,
          }),
        },
      ],
      iac: [
        {
          command: iamWriteTerraform(
            serviceAccountId,
            context,
            awsPolicyOptions
          ),
        },
      ],
    },
  };
};

export const awsIamWriteInstaller: AwsInstaller["iam-write"] = {
  instructions: iamWriteInstructions,
  items: {
    login: loginInstaller,
  },
};
