import { noop } from "lodash";
import { createContext, useCallback, useMemo, useState } from "react";
import { ruleComponents } from "shared/types/workflow/constants";
import {
  ApprovalRule,
  Filter,
  IntegrationResourceRule,
  RequestorRule,
  ResourceRule,
} from "shared/types/workflow/types";

import { isValidApproval } from "../utils";
import {
  newApprovalRuleOfType,
  newRequestorRuleOfType,
  newResourceRuleOfType,
  newResourceRuleWithFilter,
  newResourceRuleWithoutFilter,
  updateResourceService,
} from "./mutations";

type EditorState = {
  name: string | undefined;
  resource: ResourceRule | undefined;
  requestor: RequestorRule | undefined;
  approval: ApprovalRule[];
};

type EditorMutations = {
  // Name Mutations
  setName: (name: string) => void;

  // Resource Mutations
  setResource: (resource: ResourceRule) => void;
  setResourceType: (type: ResourceRule["type"]) => void;
  setResourceService: (service: IntegrationResourceRule["service"]) => void;
  addOrSetResourceFilter: <T extends IntegrationResourceRule>(
    filterKey: keyof T["filters"],
    filter: Filter,
    oldFilterType?: keyof T["filters"]
  ) => void;
  removeResourceFilter: (filterKey: string) => void;

  // Requestor Mutations
  setRequestor: (requestor: RequestorRule | undefined) => void;
  setRequestorType: (type: RequestorRule["type"]) => void;

  // Approval Mutations
  setApproval: (approval: ApprovalRule[]) => void;
  setApprovalType: (index: number, type: ApprovalRule["type"]) => void;
};

type EditorComputed = {
  isComponentValid: (component: (typeof ruleComponents)[number]) => boolean;
  isValidRule: boolean;
};

export const RoutingRuleEditorContext = createContext<
  EditorState & EditorMutations & EditorComputed
>({
  name: undefined,
  setName: noop,

  resource: undefined,
  setResource: noop,
  setResourceType: noop,
  setResourceService: noop,
  addOrSetResourceFilter: noop,
  removeResourceFilter: noop,

  requestor: undefined,
  setRequestor: noop,
  setRequestorType: noop,

  approval: [],
  setApproval: noop,
  setApprovalType: noop,

  isComponentValid: () => false,
  isValidRule: false,
});

export const RoutingRuleEditorProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [name, setName] = useState<string>("");
  const [resource, setResource] = useState<ResourceRule>();
  const [requestor, setRequestor] = useState<RequestorRule>();
  const [approval, setApproval] = useState<ApprovalRule[]>([]);

  const setResourceType = useCallback((type: ResourceRule["type"]) => {
    setResource(newResourceRuleOfType(type));
  }, []);

  const setResourceService = useCallback(
    (service: IntegrationResourceRule["service"]) => {
      if (!resource) {
        return;
      }
      setResource(updateResourceService(service, resource));
    },
    [resource]
  );

  const addOrSetResourceFilter = useCallback(
    <T extends IntegrationResourceRule>(
      filterType: keyof T["filters"],
      filter: Filter,
      oldFilterType?: keyof T["filters"]
    ) => {
      if (resource?.type !== "integration") {
        return;
      }

      setResource(
        newResourceRuleWithFilter(
          resource as T,
          filterType,
          filter,
          oldFilterType
        )
      );
    },
    [resource]
  );

  const removeResourceFilter = useCallback(
    <T extends IntegrationResourceRule>(filterKey: string) => {
      if (resource?.type !== "integration") {
        return;
      }

      setResource(newResourceRuleWithoutFilter(resource as T, filterKey));
    },
    [resource]
  );

  const setRequestorType = useCallback((type: RequestorRule["type"]) => {
    setRequestor(newRequestorRuleOfType(type));
  }, []);

  const setApprovalType = useCallback(
    (index: number, type: ApprovalRule["type"]) => {
      setApproval((approval) =>
        approval.map((a, i) => (i === index ? newApprovalRuleOfType(type) : a))
      );
    },
    []
  );

  const isResourceValid = useMemo(() => {
    if (!resource) {
      return false;
    }
    if (resource.type === "integration") {
      return !!resource.service;
    }
    return true;
  }, [resource]);

  const isRequestorValid = useMemo(() => {
    if (!requestor) {
      return false;
    }
    switch (requestor.type) {
      case "user":
        return !!requestor.uid;
      case "group":
        return !!requestor.directory && !!requestor.id && !!requestor.label;
      default:
        return true;
    }
  }, [requestor]);

  const isApprovalListValid = useMemo(() => {
    return approval.length > 0 && approval.every(isValidApproval);
  }, [approval]);

  const isComponentValid = useCallback(
    (component: (typeof ruleComponents)[number]) => {
      switch (component) {
        case "resource":
          return isResourceValid;
        case "requestor":
          return isRequestorValid;
        case "approval":
          return isApprovalListValid;
        default:
          return false;
      }
    },
    [isResourceValid, isRequestorValid, isApprovalListValid]
  );

  const isValidRule = useMemo(() => {
    return isResourceValid && isRequestorValid && isApprovalListValid;
  }, [isResourceValid, isRequestorValid, isApprovalListValid]);

  return (
    <RoutingRuleEditorContext.Provider
      value={{
        name,
        setName,
        resource,
        setResource,
        setResourceType,
        setResourceService,
        addOrSetResourceFilter,
        removeResourceFilter,
        requestor,
        setRequestor,
        setRequestorType,
        approval,
        setApproval,
        setApprovalType,
        isComponentValid,
        isValidRule,
      }}
    >
      {children}
    </RoutingRuleEditorContext.Provider>
  );
};
