import { Col, Row, Spin, Switch, Typography, message } from "antd";
import { useAuthFetch } from "components/Login/hook";
import { TenantAwareLink } from "components/common/TenantAwareLink";
import { useCallback, useContext, useMemo, useState } from "react";

import { useFirestoreDoc } from "../../providers/FirestoreProvider";
import { Workflow } from "../../shared/types";
import { Tenant } from "../Login/util";
import { CustomExpiryOptions } from "./CustomExpiryOptions";

export const ApprovalRequirements: React.FC<{ isSandbox?: boolean }> = ({
  isSandbox,
}) => {
  const tenantId = useContext(Tenant);
  const [loading, setLoading] = useState<{
    allowOneParty: boolean;
    requireReason: boolean;
  }>({ allowOneParty: false, requireReason: false });
  const authFetch = useAuthFetch();
  const path = `o/${tenantId}/workflows/`;
  const defaultWorkflow = useFirestoreDoc<Workflow>(
    !isSandbox ? `${path}_default_` : undefined,
    {
      live: true,
    }
  );
  const latestWorkflow = useFirestoreDoc<Workflow>(
    !isSandbox ? `${path}latest` : undefined,
    {
      live: true,
    }
  );
  const isDisabled = isSandbox || !!latestWorkflow?.doc;
  const allowOneParty = !!defaultWorkflow?.doc?.data.allowOneParty;
  const requireReason = !!defaultWorkflow?.doc?.data.requireReason;

  const updateDefaultWorkflow = useCallback(
    (type: "allowOneParty" | "requireReason") => async (value: boolean) => {
      setLoading((prev) => ({ ...prev, [type]: true }));
      const response = await authFetch(`settings/approval-configuration`, {
        method: "PUT",
        body: JSON.stringify({ [type]: value }),
        headers: { "Content-Type": "application/json; charset=utf-8" },
        onNotOk: async (res) => {
          message.error((await res.json()).error);
          setLoading((prev) => ({ ...prev, [type]: false }));
        },
      });
      if (!response) return;
      message.success(`Successfully updated`);
      setLoading((prev) => ({ ...prev, [type]: false }));
    },
    [authFetch]
  );

  const requireReasonHandler = useMemo(
    () => updateDefaultWorkflow("requireReason"),
    [updateDefaultWorkflow]
  );

  const allowOnePartyHandler = useMemo(
    () => updateDefaultWorkflow("allowOneParty"),
    [updateDefaultWorkflow]
  );

  return (
    <Row gutter={[0, 32]}>
      <Col span={24}>
        <Typography.Title level={4}>Approvals</Typography.Title>
        {latestWorkflow?.doc && (
          <p>
            {" "}
            <Typography.Text type="warning">
              {" "}
              You have enabled a custom routing rule. Approval settings are now
              controlled through{" "}
              <TenantAwareLink to={"routing"}>routing.</TenantAwareLink>
            </Typography.Text>
          </p>
        )}
        <div>
          Allow one-party approvals?
          <br />
          <Typography.Text type="secondary">
            One-party approvals allow anyone with &ldquo;approver&rdquo; access
            to approve their own requests. This setting does not affect
            PagerDuty auto-approvals.
          </Typography.Text>
          <div style={{ display: "flex", columnGap: 12, marginTop: "1em" }}>
            <Switch
              checked={allowOneParty}
              onChange={allowOnePartyHandler}
              disabled={isDisabled}
            />
            {allowOneParty ? "Allowed" : "Not allowed "}
            <Spin spinning={loading.allowOneParty} />
          </div>
        </div>
        <br />
        <div>
          Mandatory Reason?
          <br />
          <Typography.Text type="secondary">
            Requestor will be required to provide a reason for the request.
          </Typography.Text>
          <div style={{ display: "flex", columnGap: 12, marginTop: "1em" }}>
            <Switch
              checked={requireReason}
              onChange={requireReasonHandler}
              disabled={isDisabled}
            />
            {requireReason ? "Required" : "Not Required "}
            <Spin spinning={loading.requireReason} />
          </div>
        </div>
      </Col>
      <Col span={24}>
        <CustomExpiryOptions />
      </Col>
    </Row>
  );
};
