import { ClipDiv } from "components/divs";
import { PrincipalNode } from "shared/types/assessment/data";

import { ExportColumnType } from "../../AssessmentExport";
import {
  StaleCredentialAggregate,
  UsedCredentialAggregate,
} from "../../cells/Credential";
import { CredentialTitle } from "../../cells/CredentialTitle";
import { LastAuthenticated } from "../../cells/LastAuthenticated";
import { PrincipalCell, principalCellValue } from "../../cells/Principal";
import {
  PrincipalAccess,
  PrincipalAccessTitle,
  hasPrincipalAccessWarning,
} from "../../cells/PrincipalAccess";
import { RiskAggregate, riskSorter } from "../../cells/RiskAggregate";
import { epochMillisExport, genericExport } from "../../export";
import { numberSorter, stringSorter } from "../../sort";
import { AssessmentColumnProps } from "../columns";
import {
  credentialExportFromPrincipal,
  principalLookback,
} from "./PrincipalNode";
import { credentialsByStaleness, credentialsByUsage } from "./grouping";

const replaceNaN = (value: number, fallback: number) =>
  isNaN(value) ? fallback : value;

const accessWarningList = (node: PrincipalNode) => {
  const { mfa, publicJoin, domainJoin, memberAdd, memberApprove } =
    hasPrincipalAccessWarning(node);
  const warnings = [];
  if (mfa) warnings.push("MFA disabled");
  if (publicJoin) warnings.push("Anyone can join");
  if (domainJoin) warnings.push("Anyone in domain can join");
  if (memberAdd) warnings.push("Anyone in group can add");
  if (memberApprove) warnings.push("Anyone in group can approve join requests");
  return warnings;
};
export const principalColumns = (
  props: AssessmentColumnProps
): ExportColumnType<PrincipalNode>[] => [
  {
    key: "principal",
    title: "Principal",
    sorter: (left, right) => stringSorter(left.key, right.key),
    render: (_, node) => (
      <ClipDiv>
        <PrincipalCell principalData={node.data} {...props} />
      </ClipDiv>
    ),
    export: genericExport((node) =>
      principalCellValue(node.data, props.integration)
    ),
  },
  {
    key: "lastAuthnTime",
    title: "Last Used",
    sorter: (left, right) =>
      // Ordering is reversed because it looks back in time
      numberSorter(
        replaceNaN(right.aggregates.lastAuthEvent, 0),
        replaceNaN(left.aggregates.lastAuthEvent, 0)
      ),
    render: (_, node) => (
      <ClipDiv>
        <LastAuthenticated
          principal={node.data}
          last={node.aggregates.lastAuthEvent}
          lookback={principalLookback(node.aggregates.credentials)}
        />
      </ClipDiv>
    ),
    export: epochMillisExport(
      (node) => node.aggregates?.lastAuthEvent,
      undefined
    ),
  },

  {
    key: "credentials",
    title: <CredentialTitle />,
    render: (_, node) => (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "1em",
        }}
      >
        <UsedCredentialAggregate
          credentials={credentialsByUsage(node.aggregates.credentials)}
          {...props}
        />
        <StaleCredentialAggregate
          credentials={credentialsByStaleness(node.aggregates.credentials)}
          {...props}
        />
      </div>
    ),
    export: genericExport(credentialExportFromPrincipal),
  },
  {
    key: "access",
    title: <PrincipalAccessTitle />,
    sorter: (left, right) =>
      numberSorter(
        accessWarningList(left).length,
        accessWarningList(right).length
      ),
    render: (_, node) => <PrincipalAccess node={node} {...props} />,
    export: genericExport((node) => accessWarningList(node)),
  },
  {
    key: "grant",
    title: "Grants",
    sorter: (left, right) =>
      numberSorter(
        left.aggregates.grants.length,
        right.aggregates.grants.length
      ),
    render: (_, node) => node.aggregates.grants.length,
    export: genericExport((node) => node.aggregates.grants),
  },
  {
    key: "risk",
    title: "Risks",
    sorter: (left, right) =>
      riskSorter(left.aggregates.risks, right.aggregates.risks),
    render: (_, node) => <RiskAggregate risks={node.aggregates.risks} />,
    export: genericExport((node) => node.aggregates.risks),
  },
];
