export const DEFAULT_GRAPH_SEARCH_SETTINGS: Readonly<GraphSearchSettings> = {
  maxResults: 50,
  maxPaths: 20,
  stopOn: ["lateral"] as const,
} as const;

export type GraphSearchSettings = {
  /** Maximum number of matching source nodes that can be returned */
  maxResults: number;
  /** Maximum number of paths returned per matching source node */
  maxPaths: number;
  /** Node types beyond which searches do not propagate */
  stopOn: Readonly<string[]>;
};
