import { LinkOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";

import { useRoutingRulesStore } from "../../store/routingRulesStore";
import { CodeUpload } from "../CodeUpload/CodeUpload";
import { EXAMPLE_DOCUMENT } from "./utils";

type Props = {
  onDirty?: (dirty: boolean) => void;
  handleSubmitWorkflow: ((value: string) => Promise<void>) | undefined;
  rule: string;
  setDefaultRule: () => void;
  setSampleExample: () => void;
};

const CodeEditor: React.FC<Props> = ({
  onDirty,
  handleSubmitWorkflow,
  rule,
  setDefaultRule,
  setSampleExample,
}) => {
  const flags = useFlags();
  const { errors, isSubmitting } = useRoutingRulesStore();

  return (
    <CodeUpload
      errors={errors}
      defaultValue={rule}
      readOnly={!flags.requestRouting}
      isSubmitting={isSubmitting}
      formatName="yaml"
      instructions={
        <>
          <p>
            <Button
              type="default"
              href="https://docs.p0.dev/just-in-time-access/request-routing"
              target="_blank"
              rel="noreferrer"
              icon={<LinkOutlined />}
            >
              Workflow API Reference
            </Button>
          </p>
          <p>
            Use this editor to build your workflow, which will define how
            requests are routed in your organization. The above reference docs
            will help you construct your JSON by breaking down all of the
            options.
          </p>
          <p>
            <Button
              type="default"
              disabled={!flags.requestRouting}
              onClick={setDefaultRule}
            >
              Reset editor to default P0 approval
            </Button>
          </p>
          <Typography.Title level={4}>Structure at-a-glance:</Typography.Title>
          <p>
            Your workflow is an array of &quot;Rules&quot;. Each Rule has a
            &quot;Requestor&quot;, &quot;Resource&quot;, and one or more
            &quot;Approvals&quot;.
          </p>
          <p>
            Requestor is the people who are making an access request. Resource
            is the resource(s) that the Requestor is requesting access to. And
            Approvers are the people/services who can approve/deny requests.
          </p>
          <Typography.Title level={4}>
            Here&apos;s an example workflow document
          </Typography.Title>
          <p>
            <Button
              type="default"
              disabled={!flags.requestRouting}
              onClick={setSampleExample}
            >
              Reset editor to below example
            </Button>
          </p>
          <pre>
            <code style={{ fontSize: "smaller" }}>{EXAMPLE_DOCUMENT}</code>
          </pre>
        </>
      }
      onDirty={onDirty}
      onSubmit={handleSubmitWorkflow}
    />
  );
};

export default CodeEditor;
