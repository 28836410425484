import { isNode } from "../graph/types";
import { GrantNode } from "../types/assessment/data";

/* Helper function to fetch all permissions in a grant with a given risk */
export const permissionsWithRisk = (node: GrantNode, risk: string) => {
  return node.aggregates.permissions["unused"]
    .filter(
      (p) => !!p.children.find((n) => isNode("risk")(n) && n.data.name === risk)
    )
    .map((p) => p.key);
};

/* Gets the firestore path for an assessment */
export const toAssessmentPath = (
  tenantId: string,
  assessmentId: string
): string => `o/${tenantId}/iam-assessments/${assessmentId}`;
