import { Typography } from "antd";
import { formatDistance } from "date-fns";
import { Evidence } from "shared/types/evidence";

import { renderEvidence } from "../requestRenderUtils";
import { ConversationLink } from "./ConversationLink";

export const RequestHistoryRequestedApprovedBy: React.FC<{
  slackUrl?: string;
  timestamp?: number;
  username?: string;
  evidence?: readonly [Evidence["source"], string] | "unknown";
}> = ({ slackUrl, timestamp, username, evidence }) => {
  return username ? (
    <>
      <Typography.Text>{username}</Typography.Text>
      {timestamp && (
        <div className="sub-text">
          {formatDistance(timestamp, Date.now(), {
            addSuffix: true,
          })}
          {slackUrl && <ConversationLink url={slackUrl} />}
        </div>
      )}
      {evidence && (
        <div className="sub-text">
          <Typography.Text type="secondary">
            {renderEvidence(evidence, true)}
          </Typography.Text>
        </div>
      )}
    </>
  ) : null;
};
