import { SwapOutlined } from "@ant-design/icons";
import { Button, Segmented, Tooltip, Typography } from "antd";
import { sortBy, uniq } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { isNode } from "shared/graph/types";
import { assertNever } from "shared/types";
import { GrantAggregates, NodeFor, Usage } from "shared/types/assessment/data";
import { MonitorScope } from "shared/types/assessment/monitor";
import { Risk, RiskScore } from "shared/types/catalog";
import { widetype } from "shared/util/collections";

import { Cascade, CascadeNode } from "../../../Cascade";
import { CatalogContext } from "../../../Catalog/context";
import { SearchInput } from "../../../antd";
import { CountAggregate } from "./Aggregate";
import { PermissionLink } from "./PermissionLink";
import { RiskLink } from "./RiskLink";
import { HasAddTerm } from "./ShowHide";

// Display ordering
const PERMISSION_SORTING: Record<string, number> = {
  unused: 0,
  used: 1,
  unknown: 2,
};
const USAGES = ["used", "unused", "unknown"] as const;
const NO_KNOWN_RISK = "no-risk";

export const RiskPriority: Record<RiskScore, number> = {
  CRITICAL: 0,
  HIGH: 1,
  MEDIUM: 2,
  BOOST: 3,
  EVASION: 4,
  LOW: 5,
};

export const PermissionAggregate = ({
  counts,
  onAddTerm,
  terms,
}: { counts: Record<Usage, number> } & HasAddTerm) =>
  CountAggregate({
    inputMap: counts,
    typeOptions: USAGES,
    onAddTerm,
    terms,
    termName: "permissions",
    getInverseTerm: (val) => `usage:!"${val}"`,
    getSearchTerm: (val) => `usage:"${val}"`,
  });

/** Converts a permissionType ConnectedNode to a PermissionAggregate
 *
 * For use when aggregate values are unavailable. E.g., in graph
 * visualization.
 */
export const toPermissionAggregate = (
  node: NodeFor<"usage">
): GrantAggregates["permissions"] => ({
  [node.data.type]: node.children.filter(
    isNode("permission")
  ) as NodeFor<"permission">[],
});

const renderPermission = (permission: string, integration: MonitorScope) => ({
  label: <PermissionLink permission={permission} integration={integration} />,
  key: permission,
  children: [],
  sortValue: permission,
});

const renderRisk = (risk: Risk | typeof NO_KNOWN_RISK) => ({
  label:
    risk === NO_KNOWN_RISK ? (
      <Typography.Text type="secondary">(no known risks)</Typography.Text>
    ) : (
      <RiskLink risk={risk} />
    ),
  key: risk === NO_KNOWN_RISK ? risk : risk.id,
  sortValue:
    risk === NO_KNOWN_RISK
      ? "7"
      : `${RiskPriority[risk.score] ?? 6}:${risk.name}`,
});

export const RiskGroupedPermissionList: React.FC<{
  permissions: GrantAggregates["permissions"];
  integration: MonitorScope;
  initialSelected?: Usage;
  /** If true, displays a permission-type picker */
  showControl?: boolean;
}> = ({ permissions, initialSelected, integration, showControl }) => {
  const [mode, setMode] = useState<"permission" | "risk">("risk");

  // Fall back to first non-empty permissions
  const firstNonempty = useMemo(
    () =>
      sortBy(
        widetype.entries(permissions),
        ([u]) => PERMISSION_SORTING[u]
      ).find(([_, v]) => v.length)?.[0],
    [permissions]
  );

  const [selected, setSelected] = useState<number | string>(
    initialSelected ?? firstNonempty ?? "unused"
  );
  const { risks } = useContext(CatalogContext);
  const [where, setWhere] = useState<string>();

  const toggleMode = useCallback(
    () =>
      setMode(
        mode === "permission"
          ? "risk"
          : mode === "risk"
          ? "permission"
          : assertNever(mode)
      ),
    [mode]
  );

  const onWhere = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setWhere(event.target.value),
    []
  );

  const byPermission: Record<string, string[]> = useMemo(
    () =>
      Object.fromEntries(
        (permissions[selected as Usage] ?? [])
          .filter((k) => k.key.includes(where ?? ""))
          .map((n) => {
            const riskChildren = n.children.filter(isNode("risk"));
            const risks = riskChildren.length
              ? uniq(riskChildren.map((c) => c.key))
              : [NO_KNOWN_RISK];
            return [n.key, risks];
          })
      ),
    [permissions, selected, where]
  );

  const byRisk: Record<string, string[]> = useMemo(() => {
    const output: Record<string, string[]> = {};
    for (const [permission, risks] of Object.entries(byPermission)) {
      for (const risk of risks) {
        output[risk] ||= [];
        output[risk].push(permission);
      }
    }
    return output;
  }, [byPermission]);

  const options = useMemo(
    () => [
      {
        label: `Unused (${permissions.unused?.length ?? 0})`,
        value: "unused",
      } as const,
      {
        label: `Known used (${permissions.used?.length ?? 0})`,
        value: "used",
      } as const,
      {
        label: `Potentially used (${permissions.unknown?.length ?? 0})`,
        value: "unknown",
      } as const,
    ],
    [permissions]
  );

  const tree: CascadeNode[] = useMemo(() => {
    switch (mode) {
      case "risk":
        return Object.entries(byRisk).map(([r, perms]) => {
          const risk = risks[r];
          return {
            ...renderRisk(risk ?? NO_KNOWN_RISK),
            children: uniq(perms).map((p) => ({
              ...renderPermission(p, integration),
              children: [],
            })),
          };
        });
      case "permission":
        return Object.entries(byPermission).map(([p, rr]) => {
          return {
            ...renderPermission(p, integration),
            children: uniq(rr).map((r) => ({
              ...renderRisk(risks[r] ?? r),
              children: [],
            })),
          };
        });
      default:
        throw assertNever(mode);
    }
  }, [mode, byRisk, byPermission, risks, integration]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.7em",
        alignItems: "flex-start",
      }}
    >
      <div style={{ display: "flex", gap: "0.7em" }}>
        {showControl && (
          <Segmented
            options={options}
            value={selected}
            onChange={setSelected}
          />
        )}
        <Tooltip title="Toggle display between risks and permissions">
          <Button
            icon={<SwapOutlined />}
            type={mode === "permission" ? "primary" : undefined}
            onClick={toggleMode}
          />
        </Tooltip>
      </div>
      <SearchInput
        prefix="🔎"
        placeholder="Search permissions"
        onChange={onWhere}
      />

      <Cascade tree={tree} height={228} />
    </div>
  );
};
