import Editor, { OnMount } from "@monaco-editor/react";
import { Alert, Button, Col, Row, Typography } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import * as monaco from "monaco-editor";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

type CodeUploadProps = {
  defaultValue: string;
  errors: string[];
  instructions: ReactNode;
  onDirty?: (dirty: boolean) => void;
  onSubmit: ((value: string) => void) | undefined;
  readOnly?: boolean;
  formatName?: string;
  isSubmitting?: boolean;
};

export const CodeUpload: React.FC<CodeUploadProps> = ({
  defaultValue,
  errors,
  instructions,
  onDirty,
  onSubmit,
  readOnly,
  formatName,
  isSubmitting,
}) => {
  const [value, setValue] = useState(defaultValue);
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  const handleEditorDidMount: OnMount = useCallback(
    (editor) => {
      editorRef.current = editor;
      editor.updateOptions({ minimap: { enabled: false }, readOnly });

      editor.onDidChangeModelContent(() => {
        setValue(editor.getValue());
      });
    },
    [readOnly]
  );

  const handleRevert = useCallback(() => {
    setValue(defaultValue);
    editorRef.current?.setValue(defaultValue);
  }, [defaultValue]);

  const handleSubmit = useCallback(() => {
    if (editorRef.current !== null) {
      onSubmit?.(editorRef.current.getValue());
    }
  }, [onSubmit]);

  useEffect(() => {
    handleRevert();
  }, [handleRevert]);

  useEffect(() => {
    if (onDirty) {
      onDirty(value !== defaultValue);
    }
  }, [value, defaultValue, onDirty]);

  return (
    <Row justify={"center"} gutter={[24, 24]}>
      <Col xs={24} lg={16} xl={17}>
        {errors.map((e, ix) => (
          <Alert
            message={<pre style={{ fontSize: "smaller" }}>{e}</pre>}
            type="error"
            style={{ marginBottom: "10px" }}
            key={`error-${ix}`}
          />
        ))}
        {onSubmit && (
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
              marginBottom: "0.5em",
            }}
          >
            <Typography.Title level={4}>
              Upload your {formatName ?? "json"}-formatted data below
            </Typography.Title>
            <ButtonGroup>
              <Button onClick={handleRevert} disabled={value === defaultValue}>
                Revert
              </Button>
              <Button
                type="primary"
                loading={isSubmitting}
                onClick={handleSubmit}
                disabled={readOnly}
              >
                Submit
              </Button>
            </ButtonGroup>
          </div>
        )}
        <div
          style={{
            border: "1px solid #1890ff",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <Editor
            height="calc(70vh - 20px)"
            defaultLanguage={formatName ?? "json"}
            defaultValue={defaultValue}
            onMount={handleEditorDidMount}
          />
        </div>
      </Col>
      <Col xs={24} lg={8} xl={7}>
        <Typography.Title level={4}>Formatting instructions</Typography.Title>
        <div style={{ height: "70vh", overflowY: "auto" }}>{instructions}</div>
      </Col>
    </Row>
  );
};
