import { ExportOutlined } from "@ant-design/icons";
import { Alert, Typography } from "antd";
import Link from "antd/lib/typography/Link";
import { oktaApiScopesTerraform } from "components/Integrations/Okta/iac";
import { OktaScopes } from "shared/integrations/directories/okta/constants";
import {
  p0GrantRoleCommands,
  p0GrantRoleTerraform,
} from "shared/integrations/resources/aws/policy/federated";

import { AwsFederatedLoginInstaller } from ".";
import { accountReference } from "../listers";

const OKTA_CUSTOM_ROLE = "P0 App Access Manager";
const OKTA_RESOURCE_SET = "P0 Access Apps";
const FEATURE_FLAG_ROLE_POOL_SIZE = "awsRolePoolSize";

export const oktaProviderInstaller: NonNullable<
  AwsFederatedLoginInstaller["items"]["provider"]
>["options"]["okta"] = {
  items: {
    appId: {
      instructions: (_context, _id, _item, field) => ({
        help: (
          <div>
            To use Okta federation with P0:
            <ul>
              <li>
                Enable the{" "}
                <Typography.Text code>aws:SourceIdentity</Typography.Text> SAML
                attribute on your AWS federation app.
              </li>
              <li>
                Add the <Typography.Text code>okta.apps.manage</Typography.Text>
                and <Typography.Text code>okta.schemas.manage</Typography.Text>
                scopes to your P0 integration app.
              </li>
              <li>
                Create a custom resource set{" "}
                <Typography.Text code>{OKTA_RESOURCE_SET}</Typography.Text> with
                all users and your AWS account federation app
              </li>
              <li>
                Create a custom admin role{" "}
                <Typography.Text code>{
                  
                  }</Typography.Text> with
                permissions &quot;Edit users&apos; application assignments&quot;
                and &quot;Manage applications&quot;
              </li>
              <li>
                Assign the{" "}
                <Typography.Text code>{OKTA_CUSTOM_ROLE}</Typography.Text>{" "}
                custom admin role and{" "}
                <Typography.Text code>{OKTA_RESOURCE_SET}</Typography.Text>{" "}
                resource set to your P0 integration app.
              </li>
            </ul>
          </div>
        ),
        commands: {
          console: (
            <ul>
              <li>
                To enable the{" "}
                <Typography.Text code>aws:SourceIdentity</Typography.Text> SAML
                attribute, follow AWS&apos;s{" "}
                <Link
                  href={
                    "https://aws.amazon.com/blogs/security/how-to-integrate-aws-sts-sourceidentity-with-your-identity-provider/"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  IdP integration docs <ExportOutlined />
                </Link>
              </li>
              <li>
                To update scopes, select &quot;Okta API Scopes&quot; on the P0
                integration app&apos;s configuration dashboard, then make sure
                the <Typography.Text code>okta.apps.manage</Typography.Text>
                and <Typography.Text code>
                  okta.schemas.manage
                </Typography.Text>{" "}
                API scopes are granted.
              </li>
              <li>
                To create the{" "}
                <Typography.Text code>{OKTA_RESOURCE_SET}</Typography.Text>{" "}
                custom resource set, select &quot;Security&quot; &rarr;
                &quot;Administrators&quot; &rarr; &quot;Resources&quot;. Then:
                <ol>
                  <li>Click &quot;Create new resource set&quot;</li>
                  <li>
                    Enter &quot;{OKTA_RESOURCE_SET}&quot; as the resource set
                    name
                  </li>
                  <li>
                    Click &quot;Add resource&quot;, then find &quot;Users&quot;
                    and select the &quot;All users&quot; radio button. Click
                    &quot;Save selection&quot;.
                  </li>
                  <li>
                    Click &quot;Add another resource type&quot;, then find
                    &quot;Select applications&quot; and search for your AWS
                    account federation app by its name. Check the box next to
                    your federation app among the search results. Click
                    &quot;Save selection&quot;.
                  </li>
                  <li>Click &quot;Create&quot;</li>
                </ol>
              </li>
              <li>
                To create the{" "}
                <Typography.Text code>{OKTA_CUSTOM_ROLE}</Typography.Text>custom
                admin role, select &quot;Security&quot; &rarr;
                &quot;Administrators&quot; &rarr; &quot;Roles&quot;. Then:
                <ol>
                  <li>Click &quot;Create new role&quot;</li>
                  <li>Enter &quot;{OKTA_CUSTOM_ROLE}&quot; as the role name</li>
                  <li>
                    Select the following two permissions:
                    <ul>
                      <li>
                        User &rarr; Manage users &rarr; Edit users&apos;
                        application assignments
                      </li>
                      <li>Applications &rarr; Manage applications</li>
                    </ul>
                  </li>
                  <li>Click &quot;Save role&quot;</li>
                </ol>
              </li>
              <li>
                To assign the{" "}
                <Typography.Text code>{OKTA_CUSTOM_ROLE}</Typography.Text> admin
                role, select &quot;Security&quot; &rarr;
                &quot;Administrators&quot; &rarr; &quot;Overview&quot;. Then:
                <ol>
                  <li>Click &quot;Add administrator&quot;</li>
                  <li>
                    Choose your existing P0 API Integration app in the
                    &quot;Select admin&quot; selector.
                  </li>
                  <li>Click &quot;Add assignment&quot;</li>
                  <li>
                    Select{" "}
                    <Typography.Text code>{OKTA_CUSTOM_ROLE}</Typography.Text>{" "}
                    in the &quot;Role&quot; selector, and{" "}
                    <Typography.Text code>{OKTA_RESOURCE_SET}</Typography.Text>{" "}
                    in the &quot;Resource set&quot; selector
                  </li>
                  <li>Click &quot;Save changes&quot;</li>
                </ol>
              </li>
            </ul>
          ),
          iac: [
            {
              header: (
                <Typography.Paragraph>
                  Add the following to your Okta federation app&apos;s{" "}
                  <Typography.Text code>okta_app_saml</Typography.Text> resource
                  attributes:
                </Typography.Paragraph>
              ),
              command: `attribute_statements {
  type      = "EXPRESSION"
  name      = "https://aws.amazon.com/SAML/Attributes/SourceIdentity"
  namespace = "urn:oasis:names:tc:SAML:2.0:attrname-format:unspecified"
  values    = ["user.login"]
}`,
            },
            {
              header: (
                <Typography.Paragraph>
                  Update your P0 integration app&apos;s Terraform to add the{" "}
                  <Typography.Text code>okta.apps.manage</Typography.Text> and{" "}
                  <Typography.Text code>okta.schemas.manage</Typography.Text>{" "}
                  scopes:
                </Typography.Paragraph>
              ),
              command: oktaApiScopesTerraform([...OktaScopes]),
            },
            {
              header: (
                <Typography.Paragraph>
                  Update your P0 integration app&apos;s Terraform to add the{" "}
                  &quot;Application Administrator&quot; admin role:
                </Typography.Paragraph>
              ),
              command: `# Admin roles are also required. The configuration below creates a custom role with "okta.apps.manage" and  "okta.users.appAssignment.manage" permissions scoped to only the AWS Account Federation app.

# To import: terraform import "okta_admin_role_custom.p0_manager_role" {customRoleId}
resource "okta_admin_role_custom" "p0_manager_role" {
  label       = "${OKTA_CUSTOM_ROLE}"
  description = "Allows P0 Security to manage user-to-app assignments and the apps themselves"
  permissions = [
    "okta.users.appAssignment.manage",
    "okta.apps.manage"
  ]
}

# To import: terraform import "okta_resource_set.p0_managed_resources" {resourceSetId}
resource "okta_resource_set" "p0_managed_resources" {
  label       = "${OKTA_RESOURCE_SET}"
  description = "List of apps that P0 can grant users access to"
  resources = [
    "\${local.org_domain}/api/v1/users", # requires all users for the "okta.users.appAssignment.manage" permission
    "\${local.org_domain}/api/v1/apps/${field}",
  ]
}

# To import: terraform import "okta_app_oauth_role_assignment.p0_manager_role_assignment" {clientId}/{roleAssignmentId}
resource "okta_app_oauth_role_assignment" "p0_manager_role_assignment" {
  type         = "CUSTOM"
  client_id    = okta_app_oauth.p0_api_integration.client_id
  role         = okta_admin_role_custom.p0_manager_role.id
  resource_set = okta_resource_set.p0_managed_resources.id
}`,
            },
          ],
        },
      }),
      labeler: () => (
        <>
          This is the ID of the &quot;AWS Account Federation&quot; Okta app. It
          appears in the URL of the app in the Okta admin dashboard, formatted
          as{" "}
          <Typography.Text code>
            &#123;your-okta-domain&#125;/admin/app/amazon_aws/instance/&#123;app-id&#125;
          </Typography.Text>
        </>
      ),
      errorElement: () => (
        <>
          Okta app IDs should start with{" "}
          <Typography.Text code>0o</Typography.Text> and include no special
          characters.
        </>
      ),
    },
    identityProvider: {
      instructions: (context, id, _item, field) => {
        const input = {
          accountId: id,
          awsIdentityProviderName: field,
          roleCount: context.featureFlags?.[
            FEATURE_FLAG_ROLE_POOL_SIZE
          ] as number,
        };

        return {
          help: (
            <div>
              <Typography.Paragraph>
                In order to grant resource access to your users with Okta login,
                generate a pool of access roles using the commands below.
              </Typography.Paragraph>
              <Alert
                type="warning"
                showIcon
                message="After adding these roles, refresh your Okta federation app's role list"
                description={
                  'Select More > Refresh Application Data on your Okta Admin Dashboard\'s "Applications" tab.'
                }
              />
            </div>
          ),
          // TODO: ENG-1797 add terraform
          commands: {
            shell: [{ command: p0GrantRoleCommands(context, input) }],
            iac: [{ command: p0GrantRoleTerraform(context, input) }],
          },
        };
      },
      labeler: (_context, id) => (
        <>
          This is the name of the{" "}
          <Link
            href={`https://${id}.signin.aws.amazon.com/console/iam#/identity_providers`}
            target="_blank"
            rel="noopener"
          >
            AWS Identity Provider
          </Link>{" "}
          used for login
        </>
      ),
      errorElement: () => (
        <>
          AWS Identity Providers should contain no special characters other than{" "}
          <Typography.Text code>/</Typography.Text>
        </>
      ),
    },
    method: {
      labeler: () => "Only SAML sign-on is supported at this time",
      options: {
        saml: {
          items: {
            accountCount: {
              options: {
                single: {},
                multi: {
                  items: {
                    parent: {
                      optionProvider: accountReference,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
