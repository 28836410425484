import { SlackOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { SLACK_TLD } from "../requestUtils";

export const ConversationLink: React.FC<{
  url?: string;
}> = ({ url }) => {
  // Prevent most XSS with this sanitizer; note that this is theoretically redundant
  // as our backend should only write the link with trusted data from Slack anyway
  const isValid = url && new URL(url).hostname.endsWith(SLACK_TLD);
  return (
    <>
      {isValid && (
        <Button
          type="link"
          href={url}
          icon={<SlackOutlined />}
          target="_blank"
          rel="noopener noreferrer"
        />
      )}
    </>
  );
};
