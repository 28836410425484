import { Prefix } from "components/GraphTable/Prefix";
import { SearchInput } from "components/antd";
import { useCallback } from "react";

export const RequestSearch: React.FC<{
  onInputChange?: (value: string) => void;
  searchQuery?: string;
}> = ({ onInputChange, searchQuery }) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onInputChange?.(e.target.value),
    [onInputChange]
  );
  return (
    <Prefix prefix="Search">
      <SearchInput
        value={searchQuery ?? ""}
        onChange={onChange}
        placeholder={"Enter search terms..."}
        style={{
          marginBottom: "unset",
          flexGrow: 1,
        }}
      />
    </Prefix>
  );
};
