import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

type Pagination = {
  page: number;
  pageSize: number;
};

/**
 * Similar to React setState, but specifically for pagination based components that uses the
 * URL search params as the source of truth
 * @returns An array containing the pagination and a function to set the new pagination parameters
 */
export const usePagination = (): [
  Partial<Pagination>,
  (pagination: Pagination) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const searchParamPageSize = searchParams.get("pageSize");
  const page =
    searchParamPage &&
    Number.isInteger(Number(searchParamPage)) &&
    Number(searchParamPage) > 0
      ? Number(searchParamPage)
      : undefined;
  const pageSize =
    searchParamPageSize &&
    Number.isInteger(Number(searchParamPageSize)) &&
    Number(searchParamPageSize) > 0
      ? Number(searchParamPageSize)
      : undefined;

  const pagination = useMemo(() => ({ page, pageSize }), [page, pageSize]);
  const setPagination = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) => {
      setSearchParams((prev) => {
        const next = new URLSearchParams(prev);
        next.set("page", page.toString());
        next.set("pageSize", pageSize.toString());
        return next;
      });
    },
    [setSearchParams]
  );

  return [pagination, setPagination];
};
