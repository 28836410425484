import { Table } from "antd";
import stringify from "json-stable-stringify";
import { useCallback } from "react";
import {
  RequestorRule,
  ResourceRule,
  RoutingRule,
} from "shared/types/workflow/types";

import { NamePreview } from "../components/previews/NamePreview";
import { RequestorPreview } from "../components/previews/RequestorPreview";
import { ResourcePreview } from "../components/previews/ResourcePreview";
import { renderApprovalSummary } from "./common";

type Props = {
  jsonRules: RoutingRule[];
};

const SimpleTableView: React.FC<Props> = ({ jsonRules }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string | undefined) => <NamePreview name={name} />,
    },
    {
      title: "Resource",
      dataIndex: "resource",
      key: "resource",
      render: (resource: ResourceRule) => (
        <ResourcePreview resource={resource} />
      ),
    },
    {
      title: "Requestor",
      dataIndex: "requestor",
      key: "requestor",
      render: (requestor: RequestorRule) => (
        <RequestorPreview requestor={requestor} />
      ),
    },
    {
      title: "Access",
      dataIndex: "approval",
      key: "approvalCombined",
      render: renderApprovalSummary,
    },
  ];

  const stringifyRecord = useCallback(
    (record: RoutingRule) => stringify(record),
    []
  );

  return (
    <Table
      bordered
      columns={columns}
      dataSource={jsonRules}
      rowKey={stringifyRecord}
      pagination={false}
    />
  );
};

export default SimpleTableView;
