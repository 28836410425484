import { DeleteTwoTone, StopTwoTone, WarningTwoTone } from "@ant-design/icons";
import { Descriptions, List, Typography } from "antd";
import { JiraIcon } from "components/Integrations/Jira/Jira";
import { capitalize } from "lodash";
import moment from "moment";
import { useFirestoreCollection } from "providers/FirestoreProvider";
import { useContext, useMemo } from "react";
import { toAssessmentPath } from "shared/assessment/helper";
import {
  frnToId,
  frnToProjectId,
  keyFrnToServiceAccountFrn,
} from "shared/integrations/resources/gcloud/asset";
import { toScope } from "shared/types/assessment";
import { ManagedKey } from "shared/types/assessment/management";

import { toSecretLink } from "../../../../../shared/integrations/resources/gcloud/asset";
import { Tenant } from "../../Login";
import { ScopeSelect } from "../components/ScopeSelect";
import { KeyLink } from "../components/cells/KeyLink";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";
import { StyledListItem } from "../styles";

const statusToIcon = {
  ACTIVE: <WarningTwoTone />,
  DISABLED: <StopTwoTone />,
  DELETED: <DeleteTwoTone />,
};
const { Title, Link, Paragraph } = Typography;

export const Management: React.FC = () => {
  const tenantId = useContext(Tenant);

  const { assessment } = useContext(SelectedAssessmentContext);
  const { scopeKey } = useContext(ScopeContext);

  const path = useMemo(
    () =>
      `${toAssessmentPath(
        tenantId,
        assessment?.doc?.id ?? ""
      )}/management/${scopeKey}/keys`,
    [scopeKey, assessment, tenantId]
  );
  const managedKeys = useFirestoreCollection<ManagedKey>(path);
  if (!assessment) return null;
  return (
    <>
      <ScopeSelect />
      <div style={{ marginTop: "1em" }}>
        {managedKeys && (
          <div style={{ maxWidth: 800, maxHeight: 800, overflowY: "auto" }}>
            <Title level={4}>Managed Service Account Key</Title>
            <List>
              {managedKeys.map((c, ix) => {
                const projectId = frnToProjectId(c.data.current.id);
                if (!projectId) return null;
                return (
                  <StyledListItem key={ix}>
                    <Descriptions bordered layout="horizontal" column={1}>
                      <Descriptions.Item label="Service Account">
                        {c.data.account}
                      </Descriptions.Item>
                      {c.data.issue && (
                        <Descriptions.Item label="Ticket">
                          {" "}
                          <Link target="_blank" href={c.data.issue.link}>
                            {JiraIcon}&nbsp; View Jira issue
                          </Link>
                        </Descriptions.Item>
                      )}
                      <Descriptions.Item label="Owner">
                        {c.data.owner}
                      </Descriptions.Item>
                      <Descriptions.Item label="Secret">
                        <Link
                          href={toSecretLink(c.data.secret.id)}
                          target="_blank"
                          rel="noopener"
                        >
                          {c.data.secret.id}
                        </Link>
                      </Descriptions.Item>
                      <Descriptions.Item label="Active Key">
                        <Paragraph>
                          Key &nbsp;
                          <KeyLink
                            projectId={toScope(scopeKey).id}
                            serviceAccountId={frnToId(
                              keyFrnToServiceAccountFrn(c.data.current.id)
                            )}
                            keyId={frnToId(c.data.current.id)}
                          />
                        </Paragraph>
                        <Paragraph>
                          To be rotated{" "}
                          {moment(c.data.current.toRotateTimestamp).fromNow()}{" "}
                        </Paragraph>
                      </Descriptions.Item>
                      {c.data.previous && (
                        <Descriptions.Item label="Previous Key">
                          <Paragraph>
                            Key &nbsp;
                            <KeyLink
                              projectId={toScope(scopeKey).id}
                              serviceAccountId={frnToId(
                                keyFrnToServiceAccountFrn(c.data.previous.id)
                              )}
                              keyId={frnToId(c.data.previous.id)}
                            />
                          </Paragraph>
                          <Paragraph>
                            Status: {statusToIcon[c.data.previous.status]}{" "}
                            {capitalize(c.data.previous.status)}
                            {c.data.previous.status === "ACTIVE"
                              ? ", waiting for ticket to be completed"
                              : c.data.previous.status === "DISABLED"
                              ? ` ${moment(
                                  c.data.previous.disabledTimestamp
                                ).fromNow()}, to be deleted ${moment(
                                  c.data.previous.toDeleteTimestamp
                                ).fromNow()}`
                              : ` ${moment(
                                  c.data.previous.deletedTimestamp
                                )}`}{" "}
                          </Paragraph>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  </StyledListItem>
                );
              })}
            </List>
          </div>
        )}{" "}
      </div>
    </>
  );
};
