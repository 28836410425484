import { useUser } from "components/Login/hook";
import { useContext } from "react";

import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";

export const useAssessmentUrl = () => {
  const { last } = useContext(SelectedAssessmentContext);
  const assessmentId = last.doc?.data.assessmentId;
  const { orgSlug } = useUser();
  return `/o/${orgSlug}/iam-assessments/${assessmentId}`;
};
