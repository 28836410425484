import { ColumnType } from "antd/lib/table";
import { CatalogContext } from "components/Catalog/context";
import { compact } from "lodash";
import { useCallback, useContext, useMemo } from "react";
import {
  ALL_SCOPE_SENTINEL,
  iamShowOptions,
} from "shared/assessment/constants";
import { assessmentParse } from "shared/assessment/issues/presets";
import { AggregatedNode } from "shared/graph/aggregate";
import { Node } from "shared/graph/types";
import {
  AnyAggregates,
  AnyNode,
  AssessmentNodes,
  TargetNodeType,
} from "shared/types/assessment/data";

import { GraphTable, GraphTableProps } from "../../GraphTable/GraphTable";
import { ScopeContext } from "../contexts/ScopeContext";
import { AssessmentExport } from "./AssessmentExport";
import { GraphProcessingStep } from "./GraphStep";
import { nodeDataFromShow } from "./node";
import { NodeText, NodeTitler } from "./node/NodeText";

type Controls = {
  display: string;
  where: string;
  show: TargetNodeType;
};

export const AssessmentGraph: React.FC<
  Pick<
    GraphTableProps<AssessmentNodes, TargetNodeType, AnyAggregates>,
    | "frozen"
    | "graph"
    | "onSearch"
    | "onSelection"
    | "scopeNode"
    | "searchExtra"
  > & {
    controls: Controls;
    scopeKey: string;
    onControls: (controls: Controls) => void;
    extraColumns?: ColumnType<Node<any, any>>[];
    step: GraphProcessingStep;
  }
> = ({
  extraColumns,
  controls,
  frozen,
  graph: inputGraph,
  onControls,
  scopeKey,
  onSelection,
  onSearch,
  scopeNode,
  searchExtra,
  step,
}) => {
  const catalog = useContext(CatalogContext);
  const { integration } = useContext(ScopeContext);

  const search = useMemo(() => {
    const where = compact([frozen?.terms, controls.where]).join(" ");
    // TODO: Fix TypeScript clobber; not sure why it can't figure this out
    return assessmentParse(where);
  }, [controls, frozen]);

  const onChangeTerms = useCallback(
    (terms: string) => onControls({ ...controls, where: terms }),
    [controls, onControls]
  );

  const graphControls = useMemo(() => {
    const onChangeDisplay = (display: string) =>
      onControls({ ...controls, display });
    const onChangeShow = (show: string) =>
      onControls({ ...controls, show: show as TargetNodeType });
    return {
      ...controls,
      terms: controls.where,
      onChangeDisplay,
      onChangeTerms,
      onChangeShow,
    };
  }, [controls, onChangeTerms, onControls]);

  const onAddTerm = useCallback(
    (term: string) => {
      return onChangeTerms(
        compact([...controls.where.split(" "), term]).join(" ")
      );
    },
    [controls.where, onChangeTerms]
  );

  const data = useMemo(() => {
    const data = nodeDataFromShow[controls.show];
    const graph = {
      nodes: inputGraph.nodes.filter(data.predicate),
    };
    return { ...data, graph, from: data.predicate };
  }, [controls.show, inputGraph]);

  const columns = useMemo(() => {
    if (!integration) return [];
    const columnProps = {
      onAddTerm,
      integration,
      terms: controls.where,
      show: controls.show,
      catalog,
    };
    return [
      ...(extraColumns ?? []),
      ...data.columns(columnProps, scopeKey),
    ] as ColumnType<AnyNode>[];
  }, [
    onAddTerm,
    integration,
    controls.where,
    controls.show,
    catalog,
    extraColumns,
    data,
    scopeKey,
  ]);

  const exporter = useCallback(
    (
      selected: AggregatedNode<AssessmentNodes, TargetNodeType, AnyAggregates>[]
    ) => <AssessmentExport selected={selected} columns={columns} />,
    [columns]
  );

  const visualize = useMemo(
    () =>
      integration
        ? {
            renderer: NodeText(undefined, integration),
            titler: NodeTitler(integration),
          }
        : undefined,
    [integration]
  );

  const showOptions = useMemo(
    () => iamShowOptions(scopeKey === ALL_SCOPE_SENTINEL ? "all" : "item"),
    [scopeKey]
  );

  return step === "done" && visualize ? (
    <GraphTable<AssessmentNodes, TargetNodeType, AnyAggregates>
      {...data}
      columns={columns}
      controls={graphControls}
      exporter={exporter}
      frozen={frozen}
      onSelection={onSelection}
      onSearch={onSearch}
      scopeNode={scopeNode}
      showOptions={showOptions}
      search={search}
      searchExtra={searchExtra}
      visualize={visualize}
    />
  ) : (
    <GraphProcessingStep step={step} />
  );
};
