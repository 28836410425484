import { ItemAssessmentScope } from "../assessment";

export const JobStatuses = [
  "COLLECTING",
  "COMPLETED",
  "CREATED",
  "DETECTING",
  "MANAGING",
  "GRAPHING",
  "ERRORED_ERRORED",
  "ERRORED",
  "PREPARING",
] as const;
export type JobStatus = (typeof JobStatuses)[number];

export const TerminalAssessmentStatuses = [
  "COMPLETED",
  "ERRORED",
  "ERRORED_ERRORED",
] as const;

export type AssessmentJobBase = {
  requestedTimestamp: number;
  scope: ItemAssessmentScope[];
  jobId: string;
  assessmentId: string;
  error?: { message: string };
};

export type AssessmentJob = AssessmentJobBase & {
  lastUpdatedTimestamp: number;
  status: JobStatus;
};

export type AssessmentProgressStatus =
  | "COMPLETED"
  | "ERRORED"
  | "IN_PROGRESS"
  | "NOT_STARTED";

export const AssessmentProgressTypes = [
  "PREPARATION",
  "DATA_COLLECTION",
  "GRAPHING",
  "DETECTION",
  "MANAGEMENT",
] as const;
export type AssessmentProgressType = (typeof AssessmentProgressTypes)[number];

type AssessmentProgressDescription = {
  inProgress: string;
  completed: string;
};

export const AssessmentProgressTypeDescription: Record<
  AssessmentProgressType,
  AssessmentProgressDescription
> = {
  PREPARATION: {
    inProgress: "Preparing Data Sources",
    completed: "Data Sources Prepared",
  },
  DATA_COLLECTION: {
    inProgress: "Collecting Data",
    completed: "Data Collected",
  },
  GRAPHING: {
    inProgress: "Constructing IAM Graph",
    completed: "IAM Graph Built",
  },
  DETECTION: {
    inProgress: "Evaluating Monitors",
    completed: "Monitors Evaluated",
  },
  MANAGEMENT: {
    inProgress: "Handling managed findings",
    completed: "Managed Findings",
  },
};

export type AssessmentProgress = {
  name: AssessmentProgressType;
  progress: number;
  lastUpdatedTimestamp: object;
  startedTimestamp?: object;
  createdTimeStamp: object;
  state: AssessmentProgressStatus;
  message?: string;
  assessmentId?: string;
};
