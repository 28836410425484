import { useCallback, useState } from "react";

/** Simplifies interaction with the browser local storage
 *
 * Use exactly like `useState`.
 */
export function useLocalStorage<T>(
  key: string
): [T | undefined, (value: T) => void];
export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void];
export function useLocalStorage<T>(key: string, initialValue?: T) {
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    const item = localStorage.getItem(key);
    if (!item && initialValue) {
      localStorage.setItem(key, JSON.stringify(initialValue));
      return initialValue;
    }
    if (item) return JSON.parse(item);
    return undefined;
  });

  const setValue = useCallback(
    (value: T) => {
      localStorage.setItem(key, JSON.stringify(value));
      setStoredValue(value);
    },
    [key]
  );

  return [storedValue, setValue] as const;
}
