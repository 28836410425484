/*
 * CAUTION: The values below are relied upon by the P0 CLI. Any changes made here may need to be reflected in and should
 * be tested in the P0 CLI.
 */
import { decodeBase64 } from "../../../base64";
import {
  Element,
  InstallSpec,
  ItemComponent,
  Option,
} from "../../../install/types";
import { arnInfo, isArn } from "../aws/util";
import { gcloudProjectValidator } from "../gcloud/validator";

const CLUSTER_ID_PATTERN = /^[a-zA-Z0-9_-]+$/;
const CLUSTER_SERVER_PATTERN = /^https:\/\/\S+$/;
const GKE_CLUSTER_NAME_PATTERN = /^[a-z]([-a-z0-9]{0,38}[a-z0-9])?$/;
// From https://regex101.com/r/mGnr7I/1
export const PEM_PATTERN =
  /^-----BEGIN CERTIFICATE-----(\n|\r|\r\n)([0-9a-zA-Z+/=]{64}(\n|\r|\r\n))*([0-9a-zA-Z+/=]{1,63}(\n|\r|\r\n))?-----END CERTIFICATE-----$/;

export const KubernetesComponents = InstallSpec({
  base: ItemComponent({
    label: "Kubernetes",
    description: "Kubernetes",
    type: "generated",
    hidden: true,
    schema: {
      serverKey: Element({
        label: "Server key",
        type: "string",
      }),
      serverCert: Element({
        label: "Server certificate",
        type: "string",
      }),
      caBundle: Element({
        label: "CA bundle",
        type: "string",
      }),
    },
  }),
  "iam-write": ItemComponent({
    label: "IAM management",
    description:
      "Allows P0 to grant and revoke Kubernetes access for your users.",
    type: "string",
    validator: async (id, _field) => {
      if (typeof id !== "string" || !id.match(CLUSTER_ID_PATTERN)) {
        return "Cluster identifier can only include alphanumeric characters and hyphens without whitespace.";
      }
    },
    schema: {
      endpoint: Element({
        label: "Cluster endpoint",
        description: "Cluster HTTPS URL",
        step: "new",
        type: "string",
        validator: async (_id, field) => {
          if (
            typeof field !== "string" ||
            !field.match(CLUSTER_SERVER_PATTERN)
          ) {
            return "Cluster endpoint should have the form `https://<host>[:<port>]`";
          }
        },
      }),
      ca: Element({
        label: "Cluster certificate authority",
        description:
          "The certificate authority used to validate the cluster identity",
        type: "string",
        step: "new",
        multiline: true,
        validator: async (_id, field) => {
          if (!field) {
            return "Cluster certificate is required";
          }
          const message =
            "Provide Certificate in raw pem or base64-encoded pem format";
          try {
            if (
              typeof field !== "string" ||
              (!field.match(PEM_PATTERN) &&
                !decodeBase64(field).trimEnd().match(PEM_PATTERN))
            )
              return message;
          } catch (err: any) {
            if (err.name === "InvalidCharacterError") {
              return message;
            }
          }
        },
      }),
      token: Element({
        label: "Cluster token",
        type: "encrypted",
        description: "The token used to authenticate with the cluster",
        validator: async (_id, field) => {
          if (!field) {
            return "Please provide the token of the p0-service-account-secret";
          }
        },
      }),
      connectivity: Element({
        label: "Network connectivity",
        description: "How P0 communicates with this cluster",
        type: "select",
        default: "public",
        step: "new",
        options: {
          public: Option({
            label: "Public Internet",
            description:
              "This cluster's API is available over the public Internet",
            schema: {},
          }),
          proxy: Option({
            label: "P0 proxy",
            description: "Use P0's Braekhus reverse HTTPS proxy",
            schema: {
              publicJwk: Element({
                label: "Public JWK",
                description:
                  "JWK used by the cluster to authenticate to the Braekhus proxy.", //the token and cluster authority are both needed to initialize the kubernetes client to connect to the cluster without the proxy.
                type: "string",
                multiline: true,
                validator: async (_id, field) => {
                  if (!field) {
                    return "Please provide the JWK from the proxy";
                  }
                },
              }),
            },
          }),
        },
      }),
      hosting: Element({
        label: "Hosting",
        description: "Where this cluster is hosted",
        type: "select",
        default: "aws",
        step: "new",
        options: {
          aws: Option({
            label: "Amazon Web Services",
            description:
              'Requires P0 installed for "IAM management" in the cluster\'s AWS account',
            schema: {
              arn: Element({
                label: "Cluster ARN",
                type: "string",
                step: "new",
                validator: async (field) => {
                  if (!isArn(field)) return "Not a valid AWS ARN";
                  const { service, accountId, region, resourceType } =
                    arnInfo(field);
                  if (service !== "eks") return "Not an EKS ARN";
                  if (!accountId) return "ARN is missing account ID";
                  if (!region) return "ARN is missing region";
                  if (resourceType !== "cluster") return "Not an EKS cluster";
                },
              }),
            },
          }),
          azure: Option({
            label: "Azure",
            description: "Hosted in AKS",
            schema: {},
          }),
          gcp: Option({
            label: "Google Cloud",
            description: "Hosted in GKE",
            schema: {
              project: Element({
                label: "GCP Project ID",
                type: "string",
                step: "new",
                validator: gcloudProjectValidator,
              }),
              clusterName: Element({
                label: "GKE cluster name",
                type: "string",
                step: "new",
                // https://cloud.google.com/kubernetes-engine/docs/reference/rest/v1/projects.locations.clusters
                validator: async (_id: string, field: string) => {
                  if (!field.match(GKE_CLUSTER_NAME_PATTERN))
                    return "The cluster name can have up to 40 lowercase letters, numbers, and hyphens. Must start with a letter and end with a number or a letter.";
                },
              }),
              location: Element({
                label: "Location",
                type: "select",
                default: "region",
                step: "new",
                options: {
                  region: Option({
                    label: "Region",
                    schema: {
                      region: Element({
                        label: "Region name",
                        type: "string",
                        step: "new",
                      }),
                    },
                  }),
                  zone: Option({
                    label: "Zone",
                    schema: {
                      zone: Element({
                        label: "Zone name",
                        type: "string",
                        step: "new",
                      }),
                    },
                  }),
                },
              }),
            },
          }),
        },
      }),
    },
  }),
});
