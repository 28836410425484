import { AssessmentSchema } from "shared/types/assessment/data/base";

/** Either a type of an AssessmentNode, or "_anything" for untyped queries */
export type Keytype = keyof typeof AssessmentSchema | "_anything";
/** Direct vs. reachable queries, renders as "=" or ":" */
export type Connection = "direct" | "reach";
/** Whether the term match should be exact */
export type AttributeConstraint = "contain" | "equal";
/** If "with", also constrain query by attribute */
export type SearchConstraint = AttributeConstraint | "with";

export type StagedSearch = {
  attribute: string | undefined;
  attributeConstraint: AttributeConstraint;
  connection: Connection;
  constraint: SearchConstraint;
  exclude: boolean;
  invert: boolean;
  keytype: Keytype;
  keyword: string;
};

export const emptyStagedSearch = {
  attribute: undefined,
  attributeConstraint: "contain",
  connection: "reach",
  constraint: "contain",
  exclude: false,
  invert: false,
  keyword: "",
  keytype: "_anything",
} as const;

/** Converts a staged node search into a text representation of a node search */
export const buildTerm = (staged: StagedSearch) => {
  const { attribute, attributeConstraint, connection, constraint } = staged;
  const { exclude, invert, keytype, keyword } = staged;
  const realizedConstraint =
    constraint === "with" ? attributeConstraint : constraint;
  const textTerm = realizedConstraint === "equal" ? `"${keyword}"` : keyword;
  return `${exclude ? "^" : ""}${
    keytype !== "_anything"
      ? `${keytype}${connection === "direct" ? "=" : ":"}`
      : ""
  }${attribute ? attribute + ":" : ""}${invert ? "!" : ""}${textTerm}`;
};
