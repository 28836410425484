import { StarOutlined } from "@ant-design/icons";
import { AppRoutes } from "components/App/routeConstants";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { AuthzButton } from "components/common/AuthzButton";
import { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";

import { AssessmentGraph } from "../components/AssessmentGraph";
import { NewMonitorModal } from "../components/MonitorAdmin";
import { ScopeSelect } from "../components/ScopeSelect";
import { DetailsColumn } from "../components/cells/DetailsColumn";
import { ScopeContext } from "../contexts/ScopeContext";
import { useControls } from "../hooks/useControls";

export const Explore: React.FC = () => {
  const { orgSlug, assessmentId } = useParams();
  const { controls, setControls } = useControls();
  const [modalOpen, setModalOpen] = useState(false);
  const { graph, scopeKey, step, runMetaGraph } = useContext(ScopeContext);

  const action = useMemo(() => {
    if (!assessmentId || !orgSlug || !scopeKey) return undefined;
    return [
      DetailsColumn((data) => {
        const base = `/o/${orgSlug}/${AppRoutes.IamAssessment}/${assessmentId}/explore`;
        const end = `/${encodeURIComponent(data.type)}/${encodeURIComponent(
          data.key
        )}?where=${encodeURIComponent(
          controls.where
        )}&show=${encodeURIComponent(controls.show)}&scope=${scopeKey}`;
        return {
          key: data.key,
          to: `${base}${end}`,
        };
      }),
    ];
  }, [assessmentId, controls.show, controls.where, orgSlug, scopeKey]);

  const createDisabled = useMemo(() => {
    return !graph || controls.where === "";
  }, [graph, controls.where]);

  const closeModal = useCallback(() => setModalOpen(false), [setModalOpen]);
  const openModal = useCallback(() => setModalOpen(true), [setModalOpen]);

  return (
    <>
      <AssessmentGraph
        graph={graph ?? { nodes: [] }}
        scopeKey={scopeKey}
        controls={controls}
        onControls={setControls}
        extraColumns={action}
        scopeNode={<ScopeSelect includeAll={runMetaGraph} />}
        searchExtra={
          <GraphTooltip title="Create a monitor from this search query">
            <AuthzButton
              disabled={createDisabled}
              icon={<StarOutlined />}
              roles={["owner", "iamOwner"]}
              onClick={openModal}
            />
          </GraphTooltip>
        }
        step={step}
      />
      <NewMonitorModal
        open={modalOpen}
        footer={false}
        closeModal={closeModal}
        searchTerm={controls.where}
        show={controls.show}
      />
    </>
  );
};
