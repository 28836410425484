import { InfoCircleTwoTone } from "@ant-design/icons";
import { Select } from "antd";
import Link from "antd/lib/typography/Link";
import { SpaceBetweenDiv } from "components/divs";
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IamOption } from "shared/assessment/constants";
import styled from "styled-components";

import { SearchInput } from "../../components/antd";
import { GraphTooltip } from "./GraphTooltip";
import { InteractiveTermBuilder } from "./InteractiveTermBuilder";
import { Prefix } from "./Prefix";

export interface GraphSearchProps {
  controls: {
    display: string;
    terms: string;
    show: string;
    onChangeDisplay: (value: string) => void;
    onChangeTerms: (value: string) => void;
    onChangeShow: (value: string) => void;
  };
  frozen?: {
    display?: boolean;
    terms?: string;
    show?: string;
    help?: ReactNode;
  };
  isSearching: boolean;
  searchExtra?: ReactNode;
  scopeNode?: ReactNode;
  showOptions: IamOption[];
}

// Can't use Typography.Text as it renders with incorrect background / font-color
// for tooltips.
const TooltipCode = styled.span`
  font-family: monospace;
  color: rgb(0, 171, 142);
`;

const GraphSearchHelp: React.FC<object> = () => (
  <div>
    <p>Anything you type here will filter displayed data:</p>
    <ul>
      <li>
        <TooltipCode>keyword</TooltipCode>&nbsp;&minus;&nbsp;Show data
        containing <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>column:</TooltipCode>&nbsp;&minus;&nbsp;Show data where{" "}
        <TooltipCode>column</TooltipCode> has content.
      </li>
      <li>
        <TooltipCode>column:keyword</TooltipCode>
        &nbsp;&minus;&nbsp;Show data data where{" "}
        <TooltipCode>column</TooltipCode> is linked to{" "}
        <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>column=keyword</TooltipCode>
        &nbsp;&minus;&nbsp;Show data data where{" "}
        <TooltipCode>column</TooltipCode> contains{" "}
        <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>!keyword</TooltipCode>&nbsp;&minus;&nbsp;Show data with at
        least one value not matching <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>column:!keyword</TooltipCode>&nbsp;&minus;&nbsp;Show data
        where <TooltipCode>column</TooltipCode> is linked to at least one value
        not matching <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>^term</TooltipCode>&nbsp;&minus;&nbsp;Removes items
        matching the <TooltipCode>term</TooltipCode> query from search results.
      </li>
    </ul>
    <p>
      For detailed help, see the{" "}
      {/* Deliberately keep referrer here; we'll use it for analytics */}
      <Link
        href="https://docs.p0.dev/iam-assessment/query-search"
        rel="noopener"
        target="docs"
      >
        query-search reference
      </Link>
      .
    </p>
  </div>
);

export const GraphSearch = ({
  controls,
  frozen,
  scopeNode,
  searchExtra,
  showOptions,
}: GraphSearchProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const { onChangeShow, onChangeTerms } = controls;

  const onChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChangeTerms(event.target.value),
    [onChangeTerms]
  );

  const onAddInteractive = useCallback(
    (term: string) => onChangeTerms(`${controls.terms} ${term}`.trim()),
    [controls.terms, onChangeTerms]
  );

  useEffect(() => {
    setShowLoading(false);
  }, [controls.show]);

  const handleChangeShow = useCallback(
    (value: string) => {
      setShowLoading(true);
      onChangeShow(value);
    },
    [onChangeShow]
  );

  return (
    <div
      style={{
        display: "grid",
        gap: "0.5em",
        flexGrow: "1",
      }}
    >
      <SpaceBetweenDiv style={{ alignItems: "center", width: "100%" }}>
        <div
          style={{ display: "inline-flex", alignItems: "baseline", gap: "1em" }}
        >
          <Prefix prefix={<>Show&nbsp;</>} style={{ width: "unset" }}>
            <Select
              style={{ minWidth: "100px" }}
              disabled={!!frozen?.show}
              options={showOptions}
              onChange={handleChangeShow}
              value={controls.show}
              loading={showLoading}
            />
          </Prefix>
          {scopeNode && <Prefix prefix="from">{scopeNode}</Prefix>}
        </div>
        {searchExtra}
      </SpaceBetweenDiv>
      <Prefix
        prefix={
          <GraphTooltip title={<GraphSearchHelp />} width="500px">
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Where&nbsp;
              <InfoCircleTwoTone />
            </div>
          </GraphTooltip>
        }
      >
        <div style={{ display: "flex", width: "100%" }}>
          <SearchInput
            onChange={onChangeInput}
            placeholder={
              frozen?.terms ? undefined : "Enter search terms or click +"
            }
            style={{
              marginBottom: "unset",
              minWidth: "31em",
              maxWidth: "100%",
              marginRight: 0,
            }}
            value={controls.terms}
          />
          <InteractiveTermBuilder onAddTerm={onAddInteractive} />
        </div>
      </Prefix>
    </div>
  );
};
