import { Button, Form, Modal, Select, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ErrorDisplay } from "components/Error";
import pluralize from "pluralize";
import { useCallback, useContext, useState } from "react";
import { isNode } from "shared/graph/types";
import {
  Service,
  removeApiPrefix,
} from "shared/integrations/resources/gcloud/asset";
import { Finding } from "shared/types/assessment/finding";

import { useAuthFetch } from "../../../components/Login/hook";
import { FirestoreDoc } from "../../../providers/FirestoreProvider";
import { ScopeContext } from "../contexts/ScopeContext";
import { MonitorActionProps } from "../pages/MonitorResults";
import { useFindingsStateUpdates } from "./finding/useFindingsStateUpdates";

const SECRET_TYPE = "secret";

/** Allows the user to enable automated management for the finding.
 *  Currently this is only implemented for key rotation.
 *  TODO Make the inputs generic
 */
export const FindingsManageModal: React.FC<MonitorActionProps> = ({
  allNodes,
  actOn,
  monitor,
}) => {
  const { graph } = useContext(ScopeContext);

  const userOptions = graph?.nodes
    ?.filter(
      (node) => isNode("principal")(node) && node.data.principalType === "user"
    )
    .map((n) => ({ label: n.key, value: n.key }));

  const resourceOptions = graph?.nodes
    ?.filter(
      (node) =>
        isNode("resource")(node) &&
        node.data.service === Service.secretManager &&
        node.data.type === SECRET_TYPE
    )
    .map((n) => ({
      label: removeApiPrefix(n.key),
      value: removeApiPrefix(n.key),
    }));

  const [staged, setStaged] = useState<FirestoreDoc<Finding>[]>([]);
  const [error, setError] = useState<string>();
  const [inputForm] = useForm<{ secret: string; owner: string }>();
  const authFetch = useAuthFetch(setError);

  const { manageFindings } = useFindingsStateUpdates(authFetch);

  const submitManage = useCallback(
    async ({ secret, owner }: { secret: string; owner: string }) => {
      setError(undefined);
      const response = await manageFindings(staged, secret, owner);
      if (response?.ok) setStaged([]); // otherwise 'error' is set
    },
    [staged, manageFindings]
  );

  const confirmSection = (
    <>
      <Form.Item>
        {"Manage"} {staged.length} {pluralize("finding", staged.length)}?
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          OK
        </Button>
      </Form.Item>
    </>
  );

  const openModal = useCallback(() => setStaged(actOn), [actOn]);
  const closeModal = useCallback(() => setStaged([]), []);
  return (
    <>
      <Button disabled={!allNodes?.length} onClick={openModal}>
        {" "}
        {"⚙️\u00a0Manage"}{" "}
      </Button>
      <Modal
        footer={false}
        maskClosable={false}
        open={staged.length > 0}
        onCancel={closeModal}
        style={{ minWidth: "600px" }}
      >
        {staged.length > 1 && (
          <ErrorDisplay
            title="Cannot manage findings"
            error={"Must select exactly one finding to initiate managament"}
          />
        )}
        {error && (
          <ErrorDisplay title="Could not manage findings" error={error} />
        )}
        {staged.length == 1 && (
          <Form
            form={inputForm}
            onFinish={submitManage}
            preserve={false}
            // Lower hard-coded width so close doesn't float over input
            // Note that antd overflow behavior means we can't just use a margin :(
            style={{ width: "530px" }}
          >
            <Typography.Paragraph>
              {monitor.management?.inputPrompt ?? ""}
            </Typography.Paragraph>
            <Form.Item label="Secret ID" name="secret">
              <Select
                options={resourceOptions}
                optionFilterProp="label"
                showSearch
                placeholder="Secret to store service account key"
              />
            </Form.Item>

            <Form.Item label="Owner" name="owner">
              <Select
                options={userOptions}
                optionFilterProp="label"
                showSearch
                placeholder="The owner responsible for updating the key after rotation"
              />
            </Form.Item>
            {confirmSection}
          </Form>
        )}
      </Modal>
    </>
  );
};
