import { RightAlignedDiv } from "components/divs";
import { FirestoreDoc } from "providers/FirestoreProvider";
import { PermissionRequest } from "shared/types/permission";

import { RequestExport } from "./RequestHistoryExport";
import { RequestSearch } from "./RequestHistorySearch";

export const SearchBar: React.FC<{
  onChange: (value: string) => void;
  requests: FirestoreDoc<PermissionRequest>[];
  searchQuery?: string;
}> = ({ onChange, requests, searchQuery }) => {
  return (
    <RightAlignedDiv style={{ gap: "8px", maxWidth: "85vw" }}>
      <RequestSearch onInputChange={onChange} searchQuery={searchQuery} />
      <RequestExport requests={requests} />
    </RightAlignedDiv>
  );
};
