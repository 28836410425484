import { eventYieldingFor } from "../util/sleep";
import { keyOf } from "./graph";
import {
  NodePredicate,
  NodeSearch,
  TRUE_SEARCH,
  paths,
  reachable,
} from "./search";
import { GraphSearchSettings } from "./settings";
import { ConnectedNode, DirectedGraph, GraphOf, Path } from "./types";

/** Creates a node search that returns 0-hop paths for a "from" predicated */
const init = <D extends DirectedGraph<any>>(
  from: NodePredicate<GraphOf<D>>
) => ({ ...TRUE_SEARCH, predicate: from });

/** Returns all nodes that match zero or more search terms
 *
 * Releases thread priority during run.
 */
export const discover = async <D extends DirectedGraph<any>>(
  graph: D,
  from: NodePredicate<GraphOf<D>>,
  search: NodeSearch<GraphOf<D>>[]
): Promise<D> => {
  let subgraph = reachable(graph, from, init(from));
  await eventYieldingFor(
    search,
    (s) => {
      subgraph = reachable(subgraph, from, s);
    },
    { maxOccupancyMs: 100 }
  );
  return subgraph;
};

export type DiscoverMatch<D extends DirectedGraph<any>> = {
  node: ConnectedNode<GraphOf<D>, keyof GraphOf<D>>;
  matches: {
    query: string;
    paths: Path<GraphOf<D>>[];
  }[];
};

/** Returns all paths that match zero or more search terms
 *
 * Results are grouped by individual term
 */
export const discoverPaths = async <D extends DirectedGraph<any>>(
  graph: D,
  from: NodePredicate<GraphOf<D>>,
  search: NodeSearch<GraphOf<D>>[],
  settings?: Partial<GraphSearchSettings>
): Promise<DiscoverMatch<D>[]> => {
  const { nodes } = reachable(graph, from, init(from));
  let matches: Record<string, DiscoverMatch<D>> = Object.fromEntries(
    nodes.map((node) => [keyOf<GraphOf<D>>(node), { node, matches: [] }])
  );

  await eventYieldingFor(
    search,
    (s) => {
      const nodes = Object.values(matches).map((m) => m.node);
      const output: Record<string, DiscoverMatch<D>> = {};
      for (const match of paths({ nodes } as GraphOf<D>, from, s, settings)) {
        const nodeKey = keyOf(match.node);
        const previous = matches[nodeKey];
        if (!previous) continue;
        output[nodeKey] = {
          node: match.node,
          matches: [...previous.matches, { query: s.term, paths: match.paths }],
        };
      }
      matches = output;
    },
    { maxOccupancyMs: 100 }
  );
  return Object.values(matches);
};
