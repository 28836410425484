import { Evidence } from "shared/types/evidence";

import { PermissionPreview } from "./PermissionPreview";

export const EvidencePreview: React.FC<{ evidence: Evidence }> = ({
  evidence,
}) => (
  <PermissionPreview
    requestor={`${evidence.approver?.email} (on behalf of ${evidence.principal})`}
    verb="has pre-approved access to"
    permission={evidence}
    reason={evidence.reason}
    createdAt={evidence.createdAt}
    approverDetail={evidence.approver?.name}
  />
);
