import moment from "moment";

/** No`T` separator between date and time and no time zone indication.
 *
 * By omitting the time zone, the date and time are assumed to be in the local time zone.
 * This is compatible with Excel that doesn't interpret time zones in imported data by default,
 * and assumes the data is in the local time zone.
 *
 * Google Spreadsheets behaves the same way.
 */
const EXCEL_ISO_8601_LOCAL_TZ = "YYYY-MM-DD HH:mm:ss.SSS";

export function genericExport<T>(transform: (node: T) => any) {
  return {
    toTsvCell: (node: T) => {
      const result = transform(node);
      return !result
        ? ""
        : typeof result === "string"
        ? result
        : JSON.stringify(result);
    },
    toJsonObject: transform,
  };
}

/**
 * Exports a date in epoch milliseconds as an Excel-compatible string.
 *
 * @param extract A function that extracts the epoch milliseconds from the node.
 * @param fallback The fallback value if extract returns undefined.
 * Setting the fallback itself to `undefined` removes the `lastAuthnTime` column in the JSON export, and results in empty cell in TSV export.
 */
export const epochMillisExport = <T>(
  extract: (node: T) => number | undefined,
  fallback: any
) => {
  const transform = (node: T, format?: string) => {
    const value = extract(node);
    return value ? moment(value).format(format) : fallback;
  };
  return {
    toTsvCell: (node: T) => {
      const result = transform(node, EXCEL_ISO_8601_LOCAL_TZ);
      return !result ? "" : result;
    },
    // Uses default moment.js format (ISO 8601 with time zone): YYYY-MM-DDTHH:mm:ss.SSSZ
    toJsonObject: transform,
  };
};
