import yaml from "js-yaml";
import { useCallback } from "react";
import {
  ApprovalRule,
  Directory,
  IdpGroup,
  IntegrationResourceRule,
  RequestorRule,
  ResourceRule,
} from "shared/types/workflow/types";
import { assertNever } from "utils/assert";

import { ApprovalRulePreview } from "./components/previews/ApprovalPreview";
import { RequestorRulePreview } from "./components/previews/RequestorPreview";
import { ResourceRulePreview } from "./components/previews/ResourcePreview";
import {
  ResourceFilterType,
  filterKeyOptionsByResource,
  filterTypeOptionsByResource,
} from "./constants";

export const EXAMPLE_RULE = {
  name: "Example Rule",
  requestor: {
    type: "group",
    directory: "okta",
    id: "00g5j4jojlGZMzfhM697",
    label: "Engineering",
  },
  resource: {
    type: "integration",
    service: "snowflake",
  },
  approval: [
    {
      type: "group",
      id: "dataops@yourorg.com",
      label: "Data Ops Team",
      directory: "workspace",
      options: {
        allowOneParty: false,
      },
    },
  ],
};

export const EXAMPLE_DOCUMENT = yaml.dump([EXAMPLE_RULE], { flowLevel: 4 });

export const DEFAULT_RULE = {
  name: "Default p0 rule",
  requestor: {
    type: "any",
  },
  resource: {
    type: "any",
  },
  approval: [
    {
      options: {
        allowOneParty: false,
        requireReason: false,
      },
      type: "p0",
    },
  ],
};

export const DEFAULT_DOCUMENT = yaml.dump([DEFAULT_RULE], { flowLevel: 4 });

export enum RoutingRulesView {
  YAML = "yaml",
  TABULAR = "tabular",
}

// All the logic for generating unique values and filters

export const getUniqueResources = (jsonRules: any[]) => {
  return Array.from(
    new Set(jsonRules.map((rule) => rule.resource && rule.resource.type))
  );
};

export const getResourceFilters = (uniqueResources: string[]) => {
  return uniqueResources.map((resource) => ({
    text: resource,
    value: resource,
  }));
};

export const useOnFilter = (dataIndex: string) => {
  return useCallback(
    (value: any, record: any) => {
      if (record[dataIndex] === undefined) {
        return false;
      }
      const yamlContent = yaml.dump(record[dataIndex]);
      return yamlContent.toLowerCase().includes(value.toLowerCase());
    },
    [dataIndex]
  );
};

export const getDefaultResourcePreviewRule = (
  type: ResourceRule["type"]
): ResourceRulePreview =>
  type === "integration" ? { type: "genericIntegration" } : { type };

export const getDefaultRequestorPreviewRule = (
  type: RequestorRule["type"]
): RequestorRulePreview => {
  switch (type) {
    case "any":
    case "some":
      return { type };
    case "user":
      return { type, uid: "Specific user by email address" };
    case "group":
      return { type: "genericGroup" };
    default:
      return assertNever(type);
  }
};

export const getDefaultApprovalPreviewRule = (
  type: ApprovalRule["type"]
): ApprovalRulePreview => {
  switch (type) {
    case "auto":
      return { type, integration: "pagerduty" };
    case "group":
      return { type: "genericGroup" };
    case "requestor-profile":
      return { type, profileProperty: "", directory: "okta" };
    case "p0":
    case "deny":
    case "persistent":
      return { type };
    case "escalation":
      return { type: "genericEscalation" };
    default:
      return assertNever(type);
  }
};

export const isValidApproval = (approval: ApprovalRule) => {
  switch (approval.type) {
    case "group":
      return !!approval.directory && !!approval.id && !!approval.label;
    default:
      return true;
  }
};

export const isValidResourceFilterType = (
  type: string,
  service: IntegrationResourceRule["service"]
) => {
  return Boolean(
    filterTypeOptionsByResource[service][
      type as ResourceFilterType<typeof service>
    ]
  );
};

export const generateFilterKeyOptions = (
  service: IntegrationResourceRule["service"],
  filterType: string
) => {
  const options = filterKeyOptionsByResource[service];
  if (!(filterType in options)) {
    return undefined;
  }
  return options[filterType as ResourceFilterType<typeof service>];
};

export const buildIdpGroupId = (group: IdpGroup) => {
  return `${group.directory}_${group.id}`;
};

export const parseIdpGroupId = (id: string): IdpGroup => {
  const [directory, idpId] = id.split("_");

  if (!directory || !idpId) {
    throw new Error("Invalid idp group id");
  }

  return {
    type: "group",
    directory: directory as Directory,
    id: idpId,
    label: "",
  };
};
