import { Button, Empty, Modal, Typography } from "antd";
import Spin from "antd/lib/spin";
import { RequestStatuses } from "shared/types/request-status";

import { Heading } from "../../Heading";
import { useUser } from "../../Login/hook";
import { useSandbox } from "../../Sandbox/hooks";
import { Requests } from "../components/Requests";
import { useRequests } from "../hooks/useRequests";

export const RequestHistory: React.FC = () => {
  const { tenant } = useUser();
  const { requestsVisited, visitRequests } = useSandbox(
    tenant.state === "found" && tenant.isSandbox
  );
  const requestHistory = useRequests(RequestStatuses);

  return (
    <>
      <Heading title="Requests" />
      {requestHistory.loading ? (
        <Spin />
      ) : requestHistory.requests.length ? (
        <Requests requests={requestHistory.requests} />
      ) : (
        <Empty description="You don't have any requests yet." />
      )}
      <Modal
        open={requestsVisited === false}
        closable={false}
        footer={[
          <Button key="submit" type="primary" onClick={visitRequests}>
            Got it!
          </Button>,
        ]}
      >
        <Typography.Title level={3}>
          Review your request history
        </Typography.Title>
        <Typography.Paragraph>
          This page contains all the requests that your team has made using P0.
          You can review requests, see their individual process history, and
          export them to tsv files.
        </Typography.Paragraph>
        <Typography.Paragraph italic>
          Hint: Try clicking into a request details to see how it was handled.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Both requesting and responding to (approving or denying) requests
          happen 100% within Slack to keep your team in their normal tools.{" "}
          <em>
            Your team won&apos;t need to sign into this web app to handle their
            requests.
          </em>
        </Typography.Paragraph>
      </Modal>
    </>
  );
};
