import { Tag } from "antd";
import Tooltip from "antd/es/tooltip";
import { ColumnTitle } from "antd/lib/table/interface";
import React, { useCallback } from "react";
import { PermissionRequest } from "shared/types/permission";

import { TAG_COLORS } from "../requestUtils";

export const FilterTag: React.FC<{
  text: React.Key;
  fieldName: string;
  handleDelete: (
    selectedFilter: React.Key,
    columnName: ColumnTitle<PermissionRequest>
  ) => void;
  color: string | undefined;
}> = ({ color, text, fieldName, handleDelete }) => {
  const closeTag = useCallback(() => {
    handleDelete(text, fieldName);
  }, [fieldName, handleDelete, text]);
  return (
    <Tooltip placement="top" title={text}>
      <Tag
        closable
        color={`${color ? color : TAG_COLORS.grey}`}
        onClose={closeTag}
        style={{
          height: "2em",
          maxWidth: "20em",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {fieldName}
        {": "}
        {text}
      </Tag>
    </Tooltip>
  );
};
