import { CatalogContext } from "components/Catalog/context";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { VerticalSpacedDiv } from "components/divs";
import { capitalize } from "lodash";
import { ReactNode, useContext } from "react";
import { grantNodeToPermissionSet } from "shared/assessment/render";
import { Node, isNode } from "shared/graph/types";
import {
  AssessmentNodes,
  GrantNode,
  NodeFor,
  PrincipalNode,
} from "shared/types/assessment/data";
import { MonitorScope } from "shared/types/assessment/monitor";

import { PermissionLink } from "../../components/cells/PermissionLink";
import { toConsumerText } from "../cells/ConsumersList";
import { CredentialDisplay } from "../cells/Credential";
import { Lateral } from "../cells/Lateral";
import {
  RiskGroupedPermissionList,
  toPermissionAggregate,
} from "../cells/PermissionAggregate";
import { PrincipalCell } from "../cells/Principal";
import { Resource } from "../cells/Resource";
import { RiskLink } from "../cells/RiskLink";

const HydratedRiskLink: React.FC<{ id: string }> = ({ id }) => {
  const { risks } = useContext(CatalogContext);
  return id in risks ? <RiskLink risk={risks[id]} /> : <>{id}</>;
};

export const NodeTitler =
  (integration: MonitorScope) =>
  (node: Node<AssessmentNodes, keyof AssessmentNodes>) =>
    capitalize(NodeLabel(node, integration));

export const NodeLabel = (
  node: Node<AssessmentNodes, keyof AssessmentNodes>,
  integration: MonitorScope
) =>
  node.type === "grant"
    ? "grant"
    : node.type === "permissionSet" && integration === "gcloud"
    ? "role"
    : node.type === "usage"
    ? "usage"
    : node.type;

export const NodeText = (
  current: NodeFor<keyof AssessmentNodes> | undefined,
  integration: MonitorScope,
  options?: { detailed?: boolean; hideThis?: boolean }
): { [K in keyof AssessmentNodes]?: (node: NodeFor<K>) => ReactNode } => ({
  consumer: (n) => toConsumerText(n.data),
  credential: (n) => <CredentialDisplay credential={n.data} id={n.key} />,
  grant: (n) => {
    const main =
      !options?.hideThis && current?.type === "grant" && current?.key === n.key
        ? "This grant"
        : grantNodeToPermissionSet(n);

    const content = options?.detailed ? (
      <VerticalSpacedDiv style={{ gap: "0em" }}>
        <div>{main} to</div>
        <div>{n.data.principal}</div>
      </VerticalSpacedDiv>
    ) : (
      main
    );
    return <GraphTooltip title={content}>{content}</GraphTooltip>;
  },
  lateral: (n) => <Lateral node={n} />,
  usage: (n) => {
    const permissions =
      (current as GrantNode | PrincipalNode)?.aggregates?.permissions ??
      toPermissionAggregate(n);
    // If `count` on data, then this is all-targets view, and the permission
    // aggregate will always be empty
    const hoverElement = !n.data.count && (
      <RiskGroupedPermissionList
        permissions={permissions}
        initialSelected={n.data.type}
        integration={integration}
      />
    );
    const labelElement = (
      <>
        {n.data.type === "unknown"
          ? "Potentially used"
          : capitalize(n.data.type)}{" "}
        permissions (
        {n.data.count ?? n.children.filter(isNode("permission")).length})
      </>
    );
    return hoverElement ? (
      <GraphTooltip width="500px" title={hoverElement}>
        {labelElement}
      </GraphTooltip>
    ) : (
      labelElement
    );
  },
  permission: (n) => (
    <PermissionLink key={n.key} permission={n.key} integration={integration} />
  ),
  principal: (n) => <PrincipalCell principalData={n.data} />,
  resource: (n) => <Resource resource={n} shortPath />,
  risk: (n) => <HydratedRiskLink key={n.key} id={n.key} />,
});
