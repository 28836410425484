import { SettingOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import {
  GraphVisualization,
  NodeFlowRenderer,
} from "components/Assessment/components/GraphVisualization";
import { VerticalSpacedDiv } from "components/divs";
import dagre from "dagre";
import { compact, uniqBy } from "lodash";
import { ReactNode, useMemo } from "react";
import { DiscoverMatch } from "shared/graph/discover";
import { keyOf } from "shared/graph/graph";
import { GraphSearchSettings } from "shared/graph/settings";
import { ConnectedNode, Node } from "shared/graph/types";
import { DirectedGraph } from "shared/graph/types";
import { join } from "utils/join";

export type DiscoverVisualizationProps<G extends object> = {
  matches: DiscoverMatch<DirectedGraph<G>>[];
  renderer: NodeFlowRenderer<G>;
  settings: GraphSearchSettings;
  titler: (node: Node<G, keyof G>) => ReactNode;
};

/** Renders a graph visualization of queried paths
 */
export const DiscoverVisualization = <G extends object>(
  props: DiscoverVisualizationProps<G>
) => {
  const { matches, renderer, settings, titler } = props;

  const { nodes, edges, warnings } = useMemo(() => {
    const before = performance.now();
    const resultLimitWarning =
      matches.length > settings.maxResults ? (
        <span key="result-limit">
          Query returned {matches.length} results, of which the first{" "}
          {settings.maxResults} are shown.
        </span>
      ) : null;
    let pathLimitWarning: JSX.Element | null = null;
    const limited = matches.slice(0, settings.maxResults);
    const nodes: ConnectedNode<G, keyof G>[] = [];
    const edges: [Node<G, keyof G>, Node<G, keyof G>][] = [];
    for (const n of limited) {
      nodes.push(n.node);
      for (const m of n.matches) {
        if (m.paths.length > settings.maxPaths) {
          pathLimitWarning = (
            <span key="path-limit">
              Query returned one or more matches for which only the first{" "}
              {settings.maxPaths} paths are shown.
            </span>
          );
        }
        for (const p of m.paths.slice(0, settings.maxPaths)) {
          let s = p[0];
          nodes.push(s);
          for (const n of p.slice(1)) {
            nodes.push(n);
            edges.push([s, n]);
            s = n;
          }
        }
      }
    }
    const howToFixWarning =
      resultLimitWarning || pathLimitWarning ? (
        <div key="edit-info">
          You can edit these limits using the <SettingOutlined /> control above.
        </div>
      ) : undefined;
    const uniqueNodes = uniqBy(nodes, (n) => keyOf(n));
    const uniqueEdges = uniqBy(edges, (e) => e.map(keyOf));
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ nodesep: 0, edgesep: 0, rankdir: "LR", ranksep: 0 });
    for (const node of uniqueNodes) {
      dagreGraph.setNode(keyOf(node), { width: 1, height: 1 });
    }
    for (const [parent, child] of uniqueEdges) {
      dagreGraph.setEdge(keyOf(parent), keyOf(child));
    }
    dagre.layout(dagreGraph);
    let maxX = 0,
      maxY = 0;
    for (const n of uniqueNodes) {
      const d = dagreGraph.node(keyOf(n));
      maxX = Math.max(d.x, maxX);
      maxY = Math.max(d.y, maxY);
    }
    const flowNodes = uniqueNodes.map((n) => {
      const d = dagreGraph.node(keyOf(n));
      return {
        inner: n,
        display: "inner" as const,
        position: { d: 0, x: d.x - maxX / 2, y: d.y - maxY / 2 },
      };
    });
    const flowEdges = uniqueEdges.map(([parent, child]) => ({ parent, child }));
    const after = performance.now();
    /* eslint-disable no-console */
    console.log(
      "Time to construct graph visualization",
      (after - before).toFixed(1),
      "ms"
    );
    console.log("  nodes:", flowNodes.length, "edges:", flowEdges.length);
    /* eslint-enable no-console */
    return {
      nodes: flowNodes,
      edges: flowEdges,
      warnings: compact([
        resultLimitWarning,
        pathLimitWarning,
        howToFixWarning,
      ]),
    };
  }, [matches, settings]);

  return (
    <VerticalSpacedDiv>
      {warnings.length ? (
        <div
          style={{
            left: "+12px",
            height: 0,
            marginTop: "0",
            position: "relative",
            width: "fit-content",
            top: "+20px",
            zIndex: "1",
          }}
        >
          <Alert
            type="warning"
            message={join(warnings, " ")}
            // Float over graph visualization
            style={{
              maxWidth: "1000px",
            }}
          />
        </div>
      ) : null}
      <GraphVisualization
        edges={edges}
        nodes={nodes}
        renderer={renderer}
        titler={titler}
      />
    </VerticalSpacedDiv>
  );
};
