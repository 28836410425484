import { Typography } from "antd";
import { formatDistance } from "date-fns";
import { Link } from "react-router-dom";
import { Permission } from "shared/types/permission";

import { requestDescription } from "../requestUtils";
import { PermissionLocation } from "./PermissionLocation";

export const PermissionPreview: React.FC<{
  requestor: string;
  verb: string;
  reason?: string;
  permission: Permission;
  createdAt?: number;
  approverType?: string;
  approverDetail?: string;
  detailsHref?: string;
}> = ({
  requestor,
  verb,
  reason,
  permission,
  createdAt: approvedAt,
  approverType,
  approverDetail,
  detailsHref,
}) => {
  return (
    <div>
      <Typography.Paragraph style={!reason ? { marginBottom: 0 } : {}}>
        <Typography.Text code>{requestor}</Typography.Text> {verb}{" "}
        <Typography.Text strong>
          {requestDescription(permission)}
        </Typography.Text>{" "}
        in <PermissionLocation permission={permission} />.
        {approvedAt && (
          <Typography.Text>
            {" "}
            Approved {formatDistance(approvedAt, Date.now())} ago by{" "}
            <Typography.Text code={approverType !== "evidence"}>
              {approverDetail}
            </Typography.Text>
            .{" "}
            {!reason && detailsHref && (
              <Link to={detailsHref}>
                <Typography.Text style={{ textDecoration: "underline" }}>
                  Details
                </Typography.Text>
              </Link>
            )}
          </Typography.Text>
        )}
      </Typography.Paragraph>
      {reason && (
        <Typography.Paragraph style={{ marginBottom: 0 }}>
          <Typography.Text type="secondary">Reason: {reason}</Typography.Text>.{" "}
          {detailsHref && (
            <Link to={detailsHref}>
              <Typography.Text style={{ textDecoration: "underline" }}>
                Details
              </Typography.Text>
            </Link>
          )}
        </Typography.Paragraph>
      )}
    </div>
  );
};
