import { DevEnv } from "../../../../types/environment";
import { MANAGED_BY_P0, P0_TAG_NAME } from "../constants";
import { samlProviderArn } from "../util";
import {
  ENV_TO_P0_AWS_SUFFIX,
  awsSuffix,
  p0GrantRoleSshPolicy,
  p0GrantsPathSegment,
} from "./shared";

const DEFAULT_GRANT_SSH_POLICY_NAME = "P0PolicySharedSSH";

// Retrieves the trailing digits of the role name, e.g. 0001 in P0GrantsRole0001
export const p0SamlGrantsRoleSuffix = (roleName: string): string => {
  const match = roleName.match(/\d+$/);
  return match ? match[0] : "";
};

// Roles prefixed with this string are assumed to be P0 grant roles for resource-based access
// Must start with leading `/`
export const p0GrantsRolePath = (context: DevEnv) =>
  `/${p0GrantsPathSegment(context)}/`;

export const qualifiedP0GrantsRoleName = (context: DevEnv, role: string) =>
  `${p0GrantsPathSegment(context)}/${role}`;

const p0SamlGrantsRole = (context: DevEnv, suffix: string) =>
  `P0GrantsRole${
    "developer" in context
      ? `${ENV_TO_P0_AWS_SUFFIX.dev}${awsSuffix(context)}`
      : ENV_TO_P0_AWS_SUFFIX[context.environment]
  }${suffix}`;

/** Trust policy for roles assumed by SAML users */
const p0GrantRoleTrustPolicy = (
  accountId: string,
  samlProviderName: string
) => ({
  Version: "2012-10-17",
  Statement: [
    {
      Effect: "Allow",
      Principal: {
        Federated: samlProviderArn(accountId, samlProviderName),
      },
      // sts:TagSession is not used currently but it allows customers to tag P0 sessions
      // to their liking. Further, it may be used by P0 in the future and doesn't present
      // a security risk.
      Action: ["sts:AssumeRoleWithSAML", "sts:TagSession"],
      Condition: {
        StringEquals: {
          "SAML:aud": "https://signin.aws.amazon.com/saml",
        },
      },
    },
    {
      Effect: "Allow",
      Principal: {
        Federated: samlProviderArn(accountId, samlProviderName),
      },
      Action: "sts:SetSourceIdentity",
    },
  ],
});

export const p0GrantRoleCommands = (
  context: DevEnv,
  data: {
    accountId: string;
    awsIdentityProviderName: string;
    roleCount: number;
  }
) => {
  const { accountId, awsIdentityProviderName, roleCount } = data;

  const policy = `for i in {0..${roleCount - 1}}; do
  aws iam create-role \\
  --tags Key="${P0_TAG_NAME}",Value="${MANAGED_BY_P0}" \\
  --role-name "${p0SamlGrantsRole(context, "$i")}" \\
  --path "${p0GrantsRolePath(context)}" \\
  --assume-role-policy-document '${JSON.stringify(
    p0GrantRoleTrustPolicy(accountId, awsIdentityProviderName),
    undefined,
    2
  )}' \\
  && \\
  aws iam put-role-policy \\
  --role-name "${p0SamlGrantsRole(context, "$i")}" \\
  --policy-name "${DEFAULT_GRANT_SSH_POLICY_NAME}" \\
  --policy-document '${JSON.stringify(
    p0GrantRoleSshPolicy(accountId),
    undefined,
    2
  )}'
  done`;

  return policy;
};

export const p0GrantRoleTerraform = (
  context: DevEnv,
  data: {
    accountId: string;
    awsIdentityProviderName: string;
    roleCount: number;
  }
) => {
  return `locals {
  account_id = ${data.accountId}
  identity_provider_name = "${data.awsIdentityProviderName}"
  # Tag resource created by Terraform with the "managed-by"="terraform" tag
  tags = {
    managed-by = "terraform"
    used-by    = "P0Security"
  }
}

data "aws_iam_policy_document" "p0_grants_role_trust_policy" {
  statement {
    effect = "Allow"

    principals {
      type        = "Federated"
      identifiers = ["arn:aws:iam::\${local.account_id}:saml-provider/\${local.identity_provider_name}"]
    }

    actions = [
      "sts:AssumeRoleWithSAML",
      "sts:TagSession"
    ]

    condition {
      test     = "StringEquals"
      variable = "SAML:aud"
      values   = ["https://signin.aws.amazon.com/saml"]
    }
  }

  statement {
    effect = "Allow"

    principals {
      type        = "Federated"
      identifiers = ["arn:aws:iam::\${local.account_id}:saml-provider/\${local.identity_provider_name}"]
    }

    actions = ["sts:SetSourceIdentity"]
  }
}

# To import: terraform import "module.aws_p0_roles.aws_iam_role.p0_grants_roles[0]" {roleName}
resource "aws_iam_role" "p0_grants_roles" {
  count              = ${data.roleCount}
  name               = format("${p0SamlGrantsRole(context, "%s")}", count.index)
  path               = "${p0GrantsRolePath(context)}"
  assume_role_policy = data.aws_iam_policy_document.p0_grants_role_trust_policy.json
  inline_policy {
    name = "${DEFAULT_GRANT_SSH_POLICY_NAME}"
    policy = <<EOF
${
  // escape the dollar sign in the template string as terraform uses it for interpolation
  JSON.stringify(p0GrantRoleSshPolicy(data.accountId), undefined, 2).replace(
    /\${/g,
    "$$${"
  )
}
EOF
  }

  tags = local.tags
}`;
};
