import { useMemo } from "react";
import { useParams } from "react-router";
import { PermissionRequest } from "shared/types/permission";

import { extractApproverName } from "../requestUtils";
import { PermissionPreview } from "./PermissionPreview";

export const RequestPreview: React.FC<{
  requestId: string;
  request: PermissionRequest;
}> = ({ requestId, request }) => {
  const { orgSlug } = useParams();

  const detailsHref = `/o/${orgSlug}/requests/${requestId}`;
  const requestVerbiage = useMemo(() => {
    return {
      requestor:
        request.requestor !== request.principal
          ? `${request.requestor} (on behalf of ${request.principal})`
          : request.requestor,
      requestVerb: request.grantTimestamp ? "was granted" : "is requesting",
    };
  }, [request]);

  return (
    <PermissionPreview
      requestor={requestVerbiage.requestor}
      verb={requestVerbiage.requestVerb}
      permission={request}
      reason={request.reason}
      createdAt={request.approvalDetails?.approvedTimestamp}
      approverType={request.approvalDetails?.approvalSource}
      approverDetail={extractApproverName(request, true)}
      detailsHref={detailsHref}
    />
  );
};
