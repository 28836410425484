import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, Select, Switch, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useControls } from "components/Assessment/hooks/useControls";
import { SpaceBetweenDiv, VerticalSpacedDiv } from "components/divs";
import { sortBy } from "lodash";
import pluralize from "pluralize";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import {
  AssessmentNodeLabels,
  AssessmentSchema,
} from "shared/types/assessment/data/base";
import { widetype } from "shared/util/collections";

import { GraphTooltip } from "../GraphTooltip";
import {
  AttributeConstraint,
  Connection,
  Keytype,
  SearchConstraint,
  StagedSearch,
  buildTerm,
  emptyStagedSearch,
} from "./term-builder";

type KeytypeOption = { value: Keytype; label: string };
type ConnectionOption = { value: Connection; label: string };
type ConstraintOption = { value: SearchConstraint; label: string };

const reachOptions: ConnectionOption[] = [
  { value: "reach", label: "can reach" },
];
const connectOptions: ConnectionOption[] = [
  { value: "direct", label: "directly connect to" },
  ...reachOptions,
];

const anythingOptions: KeytypeOption[] = [
  { value: "_anything", label: "anything" },
];
const nodeOptions: KeytypeOption[] = sortBy(
  widetype.entries(AssessmentNodeLabels).map(([key, label]) => ({
    value: key,
    label,
  })),
  "label"
);
const keytypeOptions: KeytypeOption[] = [...anythingOptions, ...nodeOptions];

const anythingConstraintOptions: ConstraintOption[] = [
  { value: "contain", label: "containing" },
  { value: "equal", label: "equal to" },
];

const constraintOptions: ConstraintOption[] = [
  ...anythingConstraintOptions,
  { value: "with", label: "with" },
];

/** Form for building query terms */
const TermBuilderForm: React.FC<{ onSubmit: (term: string) => void }> = ({
  onSubmit,
}) => {
  const { controls } = useControls();
  const [staged, setStaged] = useState<StagedSearch>(emptyStagedSearch);

  // '_anything' queries are always unbounded reachability queries
  const validKeytypeOptions = useMemo(
    () => (staged.connection === "direct" ? nodeOptions : keytypeOptions),
    [staged.connection]
  );

  // Only list attributes if the searched node type has a defined attribute list
  const validConstraintOptions = useMemo(
    () =>
      staged.keytype === "_anything" ||
      AssessmentSchema[staged.keytype].length === 0
        ? anythingConstraintOptions
        : constraintOptions,
    [staged]
  );

  const attributeOptions = useMemo(
    () =>
      staged.keytype === "_anything"
        ? []
        : AssessmentSchema[staged.keytype].map((v) => ({
            value: v,
            label: v,
          })),
    [staged]
  );

  // If querying direct relationships, can't query for '_anything';
  // in this case reset the searched type to the first available type
  const handleChangeConnection = useCallback(
    (connection: Connection) =>
      setStaged({
        ...staged,
        connection,
        keytype:
          connection === "direct" && staged.keytype === "_anything"
            ? nodeOptions[1].value
            : staged.keytype,
      }),
    [staged]
  );

  // If querying '_anything' then attributes don't apply, so clear attribute
  // selection / update constraint selection if necessary
  const handleChangeType = useCallback(
    (keytype: Keytype) => {
      const noAttribute =
        keytype === "_anything" || AssessmentSchema[keytype].length === 0;
      setStaged({
        ...staged,
        keytype,
        attribute: noAttribute ? undefined : staged.attribute,
        constraint:
          noAttribute && staged.constraint === "with"
            ? "contain"
            : staged.constraint,
      });
    },
    [staged]
  );

  // If querying using a 'with' constraint, then select the first available attribute
  const handleChangeConstraint = useCallback(
    (constraint: SearchConstraint) => {
      setStaged({
        ...staged,
        constraint,
        attribute:
          constraint === "with" && staged.keytype !== "_anything"
            ? AssessmentSchema[staged.keytype][0]
            : undefined,
      });
    },
    [staged]
  );

  const handleChangeAttribute = useCallback(
    (attribute: string) => {
      setStaged({ ...staged, attribute });
    },
    [staged]
  );

  const handleChangeAttributeConstraint = useCallback(
    (attributeConstraint: AttributeConstraint) =>
      setStaged({ ...staged, attributeConstraint }),
    [staged]
  );

  const handleChangeKeyword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setStaged({ ...staged, keyword: event.target.value }),
    [staged]
  );

  const toggleExclude = useCallback(
    () => setStaged({ ...staged, exclude: !staged.exclude }),
    [staged]
  );

  const toggleInvert = useCallback(
    () => setStaged({ ...staged, invert: !staged.invert }),
    [staged]
  );

  // Query requires a keyword if any of:
  // - Querying all node types
  // - Querying with an exact match (with or without attribute)
  const requiresKeyword = useMemo(
    () =>
      staged.keytype === "_anything" ||
      staged.constraint === "equal" ||
      (!!staged.attribute && staged.attributeConstraint === "equal"),
    [staged]
  );

  const submitTerm = useCallback(() => {
    const term = buildTerm(staged);
    setStaged(emptyStagedSearch);
    onSubmit(term);
  }, [staged, onSubmit]);

  return (
    <VerticalSpacedDiv>
      <div>
        Show {pluralize(controls.show)} that
        <Select<Connection>
          disabled={staged.keytype === "_anything"}
          onChange={handleChangeConnection}
          options={
            staged.keytype === "_anything" ? reachOptions : connectOptions
          }
          style={{ marginLeft: "0.5em", width: "12em" }}
          value={staged.connection}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          flexWrap: "wrap",
          gap: "0.5em",
          // Create an indentation for multi-line content
          paddingLeft: "24px",
          width: "fit-content",
        }}
      >
        <Select<Keytype>
          onChange={handleChangeType}
          options={validKeytypeOptions}
          value={staged.keytype}
          // Enough room for "lateral movement paths"
          style={{ marginLeft: "-24px", width: "14em" }}
        />
        <Select<SearchConstraint>
          onChange={handleChangeConstraint}
          options={validConstraintOptions}
          value={staged.constraint}
          style={{ width: "8em" }}
        />
        {staged?.attribute && (
          <>
            <Select
              onChange={handleChangeAttribute}
              options={attributeOptions}
              value={staged.attribute}
              style={{ width: "14em" }}
            />
            <Select<AttributeConstraint>
              onChange={handleChangeAttributeConstraint}
              options={anythingConstraintOptions}
              value={staged.attributeConstraint}
              style={{ width: "8em" }}
            />
          </>
        )}
        <Input
          onChange={handleChangeKeyword}
          placeholder={`Enter a search term${
            requiresKeyword ? "" : ", or leave empty to match any value"
          }`}
          style={{ marginBottom: 0, width: "28em" }}
        />
        <div style={{ alignItems: "center" }}>
          <label>Remove match?&nbsp;</label>
          <Switch checked={staged.exclude} onChange={toggleExclude} />
        </div>
        <div style={{ alignItems: "center" }}>
          <label>Invert match?&nbsp;</label>
          <Switch checked={staged.invert} onChange={toggleInvert} />
        </div>
      </div>
      <SpaceBetweenDiv>
        <Typography.Text type="secondary">{buildTerm(staged)}</Typography.Text>
        <Button
          type="primary"
          disabled={requiresKeyword && !staged.keyword}
          onClick={submitTerm}
        >
          Add term
        </Button>
      </SpaceBetweenDiv>
    </VerticalSpacedDiv>
  );
};

export const InteractiveTermBuilder: React.FC<{
  onAddTerm: (term: string) => void;
}> = ({ onAddTerm }) => {
  const [builderOpen, setBuilderOpen] = useState(false);

  const openBuilder = useCallback(() => setBuilderOpen(true), []);
  const closeBuilder = useCallback(() => setBuilderOpen(false), []);

  const handleSubmit = useCallback(
    (term: string) => {
      closeBuilder();
      onAddTerm(term);
    },
    [closeBuilder, onAddTerm]
  );

  return (
    <>
      <GraphTooltip title="Interactively add a search term">
        <Button
          icon={<PlusOutlined />}
          onClick={openBuilder}
          style={{
            marginLeft: "-1px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />
      </GraphTooltip>
      <Modal
        footer={null}
        maskClosable={false}
        onCancel={closeBuilder}
        open={builderOpen}
        width="600px"
      >
        <TermBuilderForm onSubmit={handleSubmit} />
      </Modal>
    </>
  );
};
